import apiCall from 'apiCalls/journal';
import { DetailPage, ListPage } from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const JournalEntryListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Journal Entries"
      namespace="journal-entries"
      searchOptions={{ tooltip: false }}
      createUrl=""
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
      filterOptions={{ filters: ['date', 'branches'] }}
    />
  );
};

export const JournalEntryDetailPage = () => {
  return (
    <DetailPage
      title="Journal Entries"
      namespace="journal-entries"
      apiCall={apiCall}
      ItemDetail={ItemDetail}
      resourceName=""
    />
  );
};
