import { PageHeader } from '@ant-design/pro-layout';
import BreadCrumb from 'common/ui/Breadcrumb';
import { useNavigate } from 'react-router-dom';

const PageTitle = ({ title, subTitle, backUrl, showBreadscrumb, ...props }) => {
  const navigate = useNavigate();

  return (
    <PageHeader
      className="site-page-header"
      onBack={() => {
        if (backUrl) {
          navigate(backUrl);
        } else {
          navigate(-1);
        }
      }}
      title={title}
      subTitle={subTitle}
      breadcrumb={showBreadscrumb ? <BreadCrumb /> : null}
      {...props}
    />
  );
};

export default PageTitle;
