import { Button, Space, notification } from 'antd';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';
import DataTable from 'common/ui/DataTable';
import XModal from 'components/XModal';
import purchaseOrderApiCall from 'apiCalls/purchaseOrder';
import purchaseOrderItemApiCall from 'apiCalls/purchaseOrderItem';
import useTenant from 'components/use-tenant';

const sharedTableColumns = ({ business_id }) => [
  {
    title: 'Product',
    dataIndex: 'product',
    render: product => (
      <Link to={getTenantUrl(business_id, `/products/${product.id}`)}>
        {product.name}
      </Link>
    ),
  },
  {
    title: 'Variant',
    dataIndex: 'product_variant',
    render: product_variant => (product_variant ? product_variant.name : null),
  },
  {
    title: 'Price per item',
    dataIndex: 'price',
    align: 'right',
    render: (text, record) => {
      const value = formatCurrency(text);
      const productPrice =
        record.product_variant?.price || record.product?.price || 0;
      const isPriceAdjusted = record.price !== productPrice;

      return isPriceAdjusted ? (
        <span className="text-amber-700">{value}</span>
      ) : (
        value
      );
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
  },
  {
    title: 'Amount',
    dataIndex: 'sub_total',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Discount',
    dataIndex: 'discount_percentage',
    align: 'right',
    render: value => (value > 0 ? `${value}%` : '-'),
  },
  {
    title: 'Tax',
    dataIndex: 'tax',
    align: 'tax',
    render: value => (value > 0 ? formatCurrency(value) : '-'),
  },
];

const actionColumnData = {
  title: 'Action',
  key: 'action',
};

const useDeletePurchaseOrderItemMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: purchaseOrderItemApiCall.delete?.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([purchaseOrderApiCall.detail.queryKey]);
      notification.open({ message: 'Deleted' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });
};

const OrderItemDataTable = ({ dataSource = [], renderEditItemForm, order }) => {
  const { business_id } = useTenant();
  const [deletingItemId, setDeletingItemId] = React.useState(null);

  const { mutate: deleteItem, isLoading: isDeleting } =
    useDeletePurchaseOrderItemMutation();

  const getActionColumn = () => {
    return [
      {
        ...actionColumnData,
        render: (text, record) => {
          const onDelete = () => {
            setDeletingItemId(record.id);

            if (confirm('Are you sure?')) {
              deleteItem({ business_id, id: record.id });
            }
          };

          return (
            <Space>
              {renderEditItemForm && (
                <XModal
                  title="Edit"
                  onOk={() => {
                    reload();
                  }}
                >
                  {renderEditItemForm(record.id)}
                </XModal>
              )}
              <Button
                danger
                loading={isDeleting && record.id === deletingItemId}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const columns = [
    ...sharedTableColumns({ business_id }),
    ...getActionColumn(),
  ];

  // need to show all items not paging, the backend is returning all the items along with the order
  return (
    <>
      <DataTable
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        totalItems={dataSource.length}
        pagingEnabled={false}
      />

      <div className="grid grid-cols-5 gap-4 mb-5">
        <div className="col-span-3"></div>
        <div className="">
          <p>Sub Total:</p>
          <p>Tax:</p>
          <p>Discount Given:</p>
          <p>Rounding Adjustment:</p>
          <p>Total Amount:</p>
        </div>
        <div className="">
          <p>{formatCurrency(order.sub_total)}</p>
          <p>{formatCurrency(order.tax)}</p>
          <p>{formatCurrency(order.discount)}</p>
          <p>{formatCurrency(order.rounding_adjustment)}</p>
          <p>{formatCurrency(order.total_amount)}</p>
        </div>
      </div>
    </>
  );
};

export default OrderItemDataTable;
