import {
  Button,
  Card,
  Form,
  Input,
  notification,
  Select,
  Space,
  Tag,
  Typography,
  Upload,
  Row,
  Col,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {
  convertDateFormData,
  ensureFormData,
  FormattedError,
  submitData,
  userSelectDisplayFn,
} from 'utils/formData';
import { expenseStatusColors, expenseStatusLabels } from 'constants/expense';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import { formatCurrency } from 'utils/formatAmount';
import { statusOptions as options } from 'constants/status';
import { useFormBlocker } from 'hooks';
import DataTable from 'common/ui/DataTable';
import DatePicker from 'common/form/UIDatePicker';
import DebounceSelect from 'common/ui/DebouceSelect';
import XModal from 'components/XModal';
import apiCall from 'apiCalls/expense';
import branchApiCall from 'apiCalls/branch';
import expenseItemApiCall from 'apiCalls/expenseItem';
import expenseTypeApiCall from 'apiCalls/expenseType';
import txAccountApiCall from 'apiCalls/TxAccount';
import useTenant from 'components/use-tenant';

import ExpenseItemForm from './ExpenseItemForm';

const { Title } = Typography;

const dateFields = ['date'];
const nestedFields = ['user', 'branch', 'expense_type'];

const useExpenseQuery = (business_id, id) => {
  return useQuery({
    queryKey: [apiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return apiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const useDeleteExpenseItemMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: expenseItemApiCall.delete?.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([apiCall.detail.queryKey]);
      notification.open({ message: 'Deleted' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });
};

const ExpenseForm = ({
  form,
  initialValues = { status: 1001 },
  name,
  formMode = 'create',
}) => {
  const setShouldBlock = useFormBlocker(form);
  const navigate = useNavigate();
  const urlParams = useParams();
  const { id } = urlParams;
  const formData = Form.useWatch([], form) || {};
  const { business_id } = useTenant();

  // reference data hooks
  const expenseId = id || formData.id;
  const { data: item = {} } = useExpenseQuery(business_id, expenseId);

  const [shouldGoBack, setShouldGoBack] = useState(false);
  const { mutate: createItem, isLoading: isCreating } = useMutation({
    mutationFn: apiCall.create.queryFn,
    onSuccess: createdItem => {
      form.setFieldsValue({
        id: createdItem.id,
        code: createdItem.code,
        status: createdItem.status,
      });
      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: updateItem, isLoading: isUpdating } = useMutation({
    mutationFn: apiCall.edit.queryFn,
    onSuccess: () => {
      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });
  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: apiCall.delete.queryFn,
    onSuccess: () => {
      notification.open({
        type: 'success',
        message: 'Success',
      });
      navigate(-1);
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const [deletingItemId, setDeletingItemId] = React.useState(null);
  const { mutate: deleteExpenseItem, isLoading: isDeletingExpenseItem } =
    useDeleteExpenseItemMutation();

  const dataItem = { ...initialValues, ...item };

  // detect changes for auto save
  useEffect(() => {
    const isAutoSave =
      !formData.id &&
      !!formData.user &&
      !!formData.branch &&
      !!formData.date &&
      !!formData.expense_type;

    if (isAutoSave) {
      setShouldBlock(false);
      form.submit();
    }
  }, [formData]);

  useEffect(() => {
    console.log(dataItem);
    form.setFieldsValue(ensureFormData(dataItem, nestedFields, dateFields));
  }, [form, item]);

  return (
    <Form
      {...formItemLayout}
      layout="horizontal"
      form={form}
      name={name || 'expense_form'}
      onFieldsChange={() => setShouldBlock(!!formData.id)}
      onFinish={values => {
        setShouldBlock(false);
        return submitData(
          values.id ? updateItem : createItem,
          convertDateFormData(values, dateFields),
          urlParams,
          ['file']
        );
      }}
      scrollToFirstError
    >
      <Form.Item name="id">
        <Input type="hidden" />
      </Form.Item>

      <Row gutter={24}>
        <Col span={11}>
          {formMode == 'edit' && dataItem && dataItem.id > 0 ? (
            <Form.Item
              name="status"
              label="Status"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Select>
                {options.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              label="Status"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Tag color={expenseStatusColors[item.status || 1000]}>
                {expenseStatusLabels[item.status || 1000]}
              </Tag>
            </Form.Item>
          )}
          <Form.Item
            name="expense_type"
            label="Expense Type"
            required
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <DebounceSelect
              apiCall={expenseTypeApiCall.list}
              initialOption={initialValues.expense_type}
              params={{ business_id }}
              placeholder="Select expense type"
              fieldNames={{ value: 'id', label: 'name' }}
            />
          </Form.Item>
          <Form.Item
            name="branch"
            label="Branch"
            required
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <DebounceSelect
              apiCall={branchApiCall.list}
              initialOption={initialValues.branch}
              params={{ business_id }}
              placeholder="Select branch"
              fieldNames={{ value: 'id', label: 'name' }}
            />
          </Form.Item>
          <Form.Item
            name="user"
            label="Account"
            required
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <DebounceSelect
              apiCall={txAccountApiCall.list}
              initialOption={{
                id: initialValues.user?.id,
                name: initialValues.user?.name,
                account_type: initialValues.user?.account_type,
              }}
              params={{
                business_id,
                account_type__in: 'customer,supplier,payment',
              }}
              placeholder="Select account (creditor / bank & cash account)"
              displayFn={userSelectDisplayFn}
              fieldNames={{ label: 'name', value: 'id' }}
              onSelect={(_, selectedAccount) => {
                // Only pre-fill for non-payment accounts (creditor or debitor accounts)
                if (
                  selectedAccount &&
                  selectedAccount.account_type != 'payment'
                ) {
                  form.setFieldsValue({
                    billing_address: selectedAccount.address1,
                    billing_address2: selectedAccount.address2,
                    billing_address3: selectedAccount.address3,
                    contact_person: selectedAccount.person_in_charge,
                    phone_number: selectedAccount.phone,
                    email: selectedAccount.email,
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="date"
            label="Date"
            required
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <DatePicker format={'YYYY-MM-DD'} />
          </Form.Item>
          <Form.Item
            label="Expense No."
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            {dataItem.code || '[auto generated]'}
          </Form.Item>
        </Col>
        <Col span={13}>
          <Form.Item
            name="billing_address"
            label="Billing Address Line 1"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="billing_address2"
            label="Billing Address Line 2"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="billing_address3"
            label="Billing Address Line 3"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="contact_person"
            label="Contact Person"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Phone Number"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="reference_number"
            label="Reference Number"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
      </Row>

      <Card className="my-5" title={<Title level={3}>Expense Items</Title>}>
        <XModal title={'New item'} isReady={!!formData.id}>
          <ExpenseItemForm params={{ expenseId: expenseId }} />
        </XModal>
        <DataTable
          rowKey="id"
          columns={[
            {
              title: 'Particular',
              dataIndex: 'particular',
            },
            {
              title: 'Quantity',
              dataIndex: 'quantity',
              align: 'right',
            },
            {
              title: 'Price',
              dataIndex: 'price',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Amount',
              dataIndex: 'amount',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Tax',
              dataIndex: 'tax',
              align: 'right',
              render: value => formatCurrency(value),
            },
            {
              title: 'Action',
              key: 'action',
              render: (text, record) => {
                const onDelete = () => {
                  setDeletingItemId(record.id);

                  if (confirm('Are you sure?')) {
                    deleteExpenseItem({ business_id, id: record.id });
                  }
                };
                return (
                  <Space>
                    <XModal title="Edit">
                      <ExpenseItemForm
                        params={{ id: record.id, expenseId: expenseId }}
                      />
                    </XModal>
                    <Button
                      danger
                      loading={
                        isDeletingExpenseItem && record.id === deletingItemId
                      }
                      onClick={onDelete}
                    >
                      Delete
                    </Button>
                  </Space>
                );
              },
            },
          ]}
          dataSource={dataItem?.expense_items}
          totalItems={dataItem?.expense_items?.length}
          currentPage={1}
          defaultCurrent={1}
        />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-3"></div>
          <div className="">
            <p>Sub Total:</p>
            <p>Discount Given:</p>
            <p>Rounding Adjustment:</p>
            <p>Total Amount:</p>
          </div>
          <div className="">
            <p>{formatCurrency(dataItem.sub_total)}</p>
            <p>{formatCurrency(dataItem.discount)}</p>
            <p>{formatCurrency(dataItem.rounding_adjustment)}</p>
            <p>{formatCurrency(dataItem.amount)}</p>
          </div>
        </div>
      </Card>

      <Form.Item name="notes" label="Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item name="personal_notes" label="Personal Notes">
        <Input.TextArea rows={5} />
      </Form.Item>

      <Form.Item
        name="file"
        label="Attachment"
        valuePropName="fileList "
        getValueFromEvent={e => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
            onClick={() => {
              setShouldGoBack(true);
            }}
          >
            Save
          </Button>
          {id && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ business_id, id });
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ExpenseForm;
