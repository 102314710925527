import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/dateUtils';
import { formatCurrency, formatNumber } from 'utils/formatAmount';
import { getItemUrl } from 'utils/inventory';
import { formatFormalText } from 'utils/text';
import { getTenantUrl } from 'utils/urlHelpers';

export const variantColumnsGenerator = ({ business_id }) => [
  {
    title: 'Product',
    dataIndex: 'product',
    render: product => product.name,
  },
  {
    title: 'Product ID',
    dataIndex: 'product',
    render: product => product.code,
  },
  {
    title: 'Variant Name',
    dataIndex: 'name',
  },
  {
    title: 'Unit',
    dataIndex: 'product',
    render: product => product.unit,
  },
  {
    title: 'Price (RM)',
    dataIndex: 'price',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'T Purchase',
    dataIndex: 'total_purchase_quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'T Sale',
    dataIndex: 'total_sale_quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
  {
    title: 'Stock Balance',
    align: 'right',
    dataIndex: 'available_quantity',
    render: value => formatNumber(value),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={getTenantUrl(business_id, `/stock-tracking/${record.id}`)}>
            View
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const inventoryStatementColumns = ({ business_id }) => [
  {
    title: 'Date',
    dataIndex: 'date',
    render: value => formatDate(value),
  },
  {
    title: 'Particulars',
    dataIndex: 'item_code',
    align: 'left',
    render: (value, record) => (
      <>
        {formatFormalText(record.inventory_type)}{' '}
        <Link
          to={getItemUrl(business_id, record.inventory_type, record.item_id)}
        >
          {value}
        </Link>
      </>
    ),
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: value => value.name,
  },
  {
    title: 'Quantity In',
    dataIndex: 'quantity_in',
    align: 'right',
    render: value => (value ? formatNumber(value) : ''),
  },
  {
    title: 'Quantity Out',
    dataIndex: 'quantity_out',
    align: 'right',
    render: value => (value ? formatNumber(value) : ''),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    align: 'right',
    render: value => (value ? formatCurrency(value) : ''),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    align: 'right',
    render: value => (value ? formatNumber(value) : ''),
  },
];
