import { Button } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';

import apiCall from 'apiCalls/paymentTx';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';

import useTenant from 'components/use-tenant';
import { getTenantUrl } from 'utils/urlHelpers';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import PaymentTxForm from './forms/PaymentTxForm';
import PaymentVoucherItemDetail from './paymentVoucher';

export const PaymentTxListPage = (props, { params }) => {
  const { business_id } = useTenant();
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const isCompletedPage = searchParams.status === '1002';

  return (
    <ListPage
      searchOptions={{ tooltip: false }}
      filterOptions={{ filters: ['date'] }}
      title="All Payment Transactions"
      namespace="payment-transactions"
      apiCall={apiCall}
      params={params}
      columnGenerator={() => columnsGenerator({ business_id })}
      extraBtns={
        isCompletedPage ? (
          <Link
            to={getTenantUrl(
              business_id,
              `/payment-transactions/statement?${baseSearchParams.toString()}`
            )}
          >
            <Button type="primary">Statement</Button>
          </Link>
        ) : null
      }
      extra={
        isCompletedPage
          ? []
          : [
              <Button
                key="inflow"
                type="primary"
                href={getTenantUrl(
                  business_id,
                  '/payment-transactions/create/inflow'
                )}
              >
                Money In
              </Button>,
              <Button
                key="outflow"
                danger
                href={getTenantUrl(
                  business_id,
                  '/payment-transactions/create/outflow'
                )}
              >
                Payment
              </Button>,
            ]
      }
      {...props}
    />
  );
};

export const PaymentTxDetailPage = () => (
  <DetailPage
    title="Payment Transaction Detail"
    namespace="payment-transactions"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const PaymentTxEditPage = () => (
  <EditPage
    title="Payment Transaction"
    namespace="payment-transactions"
    apiCall={apiCall}
    ItemForm={PaymentTxForm}
    resourceName=""
  />
);

export const PaymentTxCreatePage = () => (
  <CreatePage
    title="Payment Transaction"
    namespace="payment-transactions"
    apiCall={apiCall}
    ItemForm={PaymentTxForm}
    resourceName=""
  />
);

export const PaymentTxInvoicePage = () => (
  <DetailPage
    title="Payment Voucher"
    apiCall={apiCall}
    ItemDetail={PaymentVoucherItemDetail}
    resourceName=""
  />
);

export { StatementPage as PaymentTxStatementPage } from './statement';
