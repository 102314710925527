import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Space,
  Upload,
  Row,
  Col,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import branchApiCall from 'apiCalls/branch';
import quotationApiCall from 'apiCalls/salesQuotation';
import userApiCall from 'apiCalls/user/all';
import DatePicker from 'common/form/UIDatePicker';
import DebounceSelect from 'common/ui/DebouceSelect';
import XModal from 'components/XModal';
import { useFormBlocker } from 'hooks';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import {
  convertDateFormData,
  ensureFormData,
  FormattedError,
  submitData,
  userSelectDisplayFn,
} from 'utils/formData';

import useTenant from 'components/use-tenant';
import SalesQuotationItemDataTable from '../DataTable';
import SalesQuotationItemForm from './SalesQuotationItemForm';

const dateFields = ['shipping_estimated_arrived', 'date', 'valid_date'];
const nestedFields = ['user', 'branch'];

const useSalesQuotationQuery = (business_id, id) => {
  return useQuery({
    queryKey: [quotationApiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return quotationApiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const SalesQuotationForm = ({ form, initialValues = {}, name }) => {
  const navigate = useNavigate();
  const setShouldBlock = useFormBlocker(form);

  const urlParams = useParams();
  const { id } = urlParams;
  const { business_id } = useTenant();
  const formData = Form.useWatch([], form) || {};

  const quotationId = id || formData?.id;
  const { data: quotation = {} } = useSalesQuotationQuery(
    business_id,
    quotationId
  );

  const [shouldGoBack, setShouldGoBack] = useState(false);

  const { mutate: createSalesQuotation, isLoading: creatingSalesQuotation } =
    useMutation({
      mutationFn: quotationApiCall.create.queryFn,
      onSuccess: createdSalesQuotation => {
        if (shouldGoBack) {
          notification.open({
            type: 'success',
            message: 'Success',
          });
          navigate(-1);
        } else {
          form.setFieldsValue({
            id: createdSalesQuotation.id,
            code: createdSalesQuotation.code,
          });
          notification.open({ message: 'Saved' });
        }
      },
      onError: error => {
        notification.open({
          type: 'error',
          message: 'Error!',
          description: <FormattedError error={error} />,
          duration: 10,
        });
      },
    });

  const { mutate: updateSalesQuotation, isLoading: updatingSalesQuotation } =
    useMutation({
      mutationFn: quotationApiCall.edit.queryFn,
      onSuccess: () => {
        if (shouldGoBack) {
          notification.open({
            type: 'success',
            message: 'Success',
          });
          navigate(-1);
        } else {
          notification.open({ message: 'Saved' });
        }
      },
      onError: error => {
        notification.open({
          type: 'error',
          message: 'Error!',
          description: <FormattedError error={error} />,
          duration: 10,
        });
      },
    });

  const dataItem = {
    ...initialValues,
    ...quotation,
  };

  // detect changes for auto save
  useEffect(() => {
    const isAutoSave =
      !formData.id &&
      !!formData.user &&
      !!formData.branch &&
      !!formData.date &&
      !!formData.valid_date;

    if (isAutoSave) {
      setShouldBlock(false);
      form.submit();
    }
  }, [formData]);

  useEffect(() => {
    form.setFieldsValue(ensureFormData(dataItem, nestedFields, dateFields));
  }, [form, quotation]);

  return (
    <Form
      {...formItemLayout}
      layout="horizontal"
      form={form}
      name={name || 'quotation_checkout_form'}
      onFieldsChange={() => setShouldBlock(!!formData.id)}
      onFinish={values => {
        setShouldBlock(false);
        if (values.id) {
          return submitData(
            updateSalesQuotation,
            convertDateFormData({ business_id, ...values }, dateFields),
            urlParams,
            ['file']
          );
        }

        return submitData(
          createSalesQuotation,
          convertDateFormData({ business_id, ...values }, dateFields),
          urlParams,
          ['file']
        );
      }}
      scrollToFirstError
    >
      <Form.Item name="id">
        <Input type="hidden" />
      </Form.Item>

      <Row gutter={24}>
        <Col span={11}>
          <Form.Item
            name="user"
            label="Account"
            required
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <DebounceSelect
              apiCall={userApiCall}
              initialOption={{
                id: initialValues.user?.id,
                name: initialValues.user?.name,
                account_type: initialValues.user?.account_type,
              }}
              params={{
                business_id,
                account_type__in: 'customer,supplier,payment',
              }}
              placeholder="Select account"
              displayFn={userSelectDisplayFn}
              fieldNames={{ label: 'name', value: 'id' }}
              onSelect={(_selectedUserId, selectedUser) => {
                // Only pre-fill for non-payment accounts (creditor or debitor accounts)
                if (selectedUser && selectedUser.account_type != 'payment') {
                  form.setFieldsValue({
                    billing_address: selectedUser.address1,
                    billing_address2: selectedUser.address2,
                    billing_address3: selectedUser.address3,
                    contact_person: selectedUser.person_in_charge,
                    phone_number: selectedUser.phone,
                    customer_email: selectedUser.email,
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="date"
            label="Date"
            required
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <DatePicker format={'YYYY-MM-DD'} />
          </Form.Item>
          <Form.Item
            name="valid_date"
            label="Valid Date"
            required
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <DatePicker format={'YYYY-MM-DD'} />
          </Form.Item>
          <Form.Item
            name="branch"
            label="Branch"
            required
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <DebounceSelect
              apiCall={branchApiCall.list}
              params={{ business_id }}
              placeholder="Select branch"
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Form.Item>
          <Form.Item
            label="Quotation No."
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            {dataItem.code || '[auto generated]'}
          </Form.Item>
        </Col>
        <Col span={13}>
          <Form.Item
            name="billing_address"
            label="Billing Address Line 1"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="billing_address2"
            label="Billing Address Line 2"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="billing_address3"
            label="Billing Address Line 3"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="contact_person"
            label="Contact Person"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Phone Number"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="customer_email"
            label="Email Address"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="shipping_address"
            label="Shipping Address"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="shipping_name"
            label="Shipping Contact"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="shipping_phone_number"
            label="Shipping Phone Number"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
      </Row>

      <Divider>Add product to quotation</Divider>

      <XModal title={'New item'} isReady={!!formData.id}>
        <SalesQuotationItemForm params={{ quotation: quotationId }} />
      </XModal>

      <SalesQuotationItemDataTable
        dataSource={dataItem.sales_quotation_items || []}
        renderEditSalesQuotationItemForm={id => (
          <SalesQuotationItemForm params={{ quotation: quotationId, id: id }} />
        )}
        quotation={quotation}
      />

      <Form.Item name="notes" label="Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item name="notes_admin" label="Personal Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item
        name="file"
        label="Attachment"
        valuePropName="fileList "
        getValueFromEvent={e => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={creatingSalesQuotation || updatingSalesQuotation}
            onClick={() => {
              setShouldGoBack(true);
            }}
          >
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default SalesQuotationForm;
