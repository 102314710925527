export async function setToken(token, user) {
  localStorage.setItem('adminSession.token', token);
  localStorage.setItem('adminSession.user', JSON.stringify(user));
}

export async function deleteToken() {
  localStorage.removeItem('adminSession.token');
  localStorage.removeItem('adminSession.user');
}

export function getToken() {
  return localStorage.getItem('adminSession.token');
}

export function getUser() {
  const user = localStorage.getItem('adminSession.user');
  return JSON.parse(user);
}

export async function setBusinessPermissions(permission = {}) {
  localStorage.setItem('adminSession.permissions', JSON.stringify(permission));
}

export function getPermissions() {
  const permissions = localStorage.getItem('adminSession.permissions');
  return JSON.parse(permissions) || {};
}
