import { Button, Tag } from 'antd';
import { expenseStatusColors, expenseStatusLabels } from 'constants/expense';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    render: value => formatDate(value),
  },
  {
    title: 'Invoice Number',
    dataIndex: 'code',
    align: 'center',
    render: (value, record) => (
      <Link to={getTenantUrl(business_id, `/expenses/${record.id}/invoice`)}>
        {value}
      </Link>
    ),
  },
  {
    title: 'Creditor Name',
    dataIndex: 'user',
    render: value => value.name,
  },
  {
    title: 'Expense Type',
    dataIndex: 'expense_type',
    render: value => (
      <Link to={getTenantUrl(business_id, `/expense-types/${value.id}`)}>
        {value.name}
      </Link>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    align: 'right',
    render: value => formatCurrency(value),
  },
  // {
  // title: 'Modified Date',
  // dataIndex: 'modified',
  // render: value => formatDateTime(value),
  // },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => {
      return (
        <Tag color={expenseStatusColors[status]}>
          {expenseStatusLabels[status]}
        </Tag>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        {
          // <Button shape="round">
          // <Link to={getTenantUrl(business_id, `/expenses/${record.id}`)}>
          // View
          // </Link>
          // </Button>
        }
        <Button
          shape="round"
          disabled={record.status == 1002 || record == 1003}
        >
          <Link to={getTenantUrl(business_id, `/expenses/${record.id}/edit`)}>
            Edit
          </Link>
        </Button>
        <Button shape="round">
          <Link
            to={getTenantUrl(business_id, `/expenses/${record.id}/invoice`)}
          >
            Invoice
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];
