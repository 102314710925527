import { Link, useSearchParams } from 'react-router-dom';
import { Button } from 'antd';
import React from 'react';

import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import apiCall from 'apiCalls/purchase';
import useTenant from 'components/use-tenant';

import { columnsGenerator } from './config';
import InvoiceItemDetail from './invoice';
import ItemDetail from './detail';
import PurchaseForm from './EditForm/PurchaseForm';
import { getTenantUrl } from '../../utils/urlHelpers';

export const PurchaseListPage = () => {
  const { business_id } = useTenant();
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const isCompletedPage = searchParams.status === '1002';

  return (
    <ListPage
      title="All Purchases"
      namespace="purchases"
      searchOptions={{ tooltip: 'Enter invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'branches'] }}
      createUrl={isCompletedPage ? '' : undefined}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const CompletedPurchaseListPage = () => {
  const { business_id } = useTenant();
  const [baseSearchParams] = useSearchParams();

  return (
    <ListPage
      title="All Completed Purchases"
      namespace="purchases"
      searchOptions={{ tooltip: 'Search by invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'branches'] }}
      apiCall={apiCall}
      params={{ status: 1002 }}
      createUrl=""
      columnGenerator={() => columnsGenerator({ business_id })}
      extraBtns={
        <Button type="primary">
          <Link
            to={getTenantUrl(
              business_id,
              `/purchases/completed/statement?${baseSearchParams.toString()}`
            )}
          >
            Statement
          </Link>
        </Button>
      }
    />
  );
};

export const PurchaseDetailPage = props => (
  <DetailPage
    title="Purchase Detail"
    namespace="purchases"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const PurchaseEditPage = () => {
  return (
    <EditPage
      title="Edit Purchase"
      namespace="purchases"
      apiCall={apiCall}
      ItemForm={PurchaseForm}
      resourceName=""
    />
  );
};

export const PurchaseCreatePage = props => {
  return (
    <CreatePage
      title="Create Purchase"
      namespace="purchases"
      apiCall={apiCall}
      ItemForm={PurchaseForm}
      resourceName=""
      {...props}
    />
  );
};

export const PurchaseInvoicePage = () => (
  <DetailPage
    title="Purchase Invoice"
    namespace="purchases"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
  />
);

export { StatementPage as CompletedPurchaseStatementPage } from './statement';
