import { useQuery } from '@tanstack/react-query';
import { Space } from 'antd';
import apiCall from 'apiCalls/business';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import BusinessCard from 'components/BusinessCard';
import MetaDecorator from 'components/MetaDecorator';
import { getUser, setBusinessPermissions } from 'utils/auth';
import { processBusinessPermission } from 'utils/staffAccountBusiness';

const AppHome = () => {
  const { data = {}, isLoading } = useQuery({
    queryKey: [apiCall.list.queryKey, {}],
    queryFn: () => apiCall.list.queryFn(),
  });

  const businesses = data.items;
  if (businesses) {
    const permissionData = processBusinessPermission(businesses);
    setBusinessPermissions(permissionData);
  }
  const user = getUser();

  return (
    <>
      <MetaDecorator title={'Home'} />
      <div className="max-w-2xl mx-auto mt-8 text-center">
        <h1>Hi {name} 👋</h1>
        <p className="text-gray-600">
          Welcome to DuitBooks - Cloud Accounting Platform.
        </p>
      </div>
      <div>
        <div className="flex flex-col">
          <Space className="flex justify-between">
            <h2 className="text-2xl font-semibold mb-4">
              Select your business
            </h2>
            {/* <Button type="primary">
              <Link to={'/account/businesses/create'}>Create</Link>
            </Button> */}
          </Space>
          <Space>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div>
                {!data.count && (
                  <div>
                    There is no business available for you. Please contact the
                    administrator or create a new one to set up a new company
                    account
                  </div>
                )}

                <div className="grid grid-cols-3 gap-x-6 gap-y-4">
                  {businesses?.map(item => (
                    <BusinessCard key={item.id} business={item} user={user} />
                  ))}
                </div>
              </div>
            )}
          </Space>
        </div>
      </div>
    </>
  );
};

export default AppHome;
