import { Typography } from 'antd';
import { compact, flatten, flow, join, map } from 'lodash/fp';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import moment from 'moment';

import { DetailPage } from 'common/ui/AdminCRUD/page';
import { formatCurrency } from 'utils/formatAmount';
import { formatDate } from 'utils/dateUtils';
import { formatFormalText } from 'utils/text';
import { useFetchApiGet } from 'common/reduxutils';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import BusinessStamp from 'common/ui/BusinessStamp';
import DataTable from 'common/ui/DataTable';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import Printable from 'components/Printable';
import businessApiCall from 'apiCalls/business';
import expenseTypeApiCall from 'apiCalls/expenseType';
import statementApiCall from 'apiCalls/expenseStatement';
import toWords from 'utils/toWords';

const { Text } = Typography;

const HeaderComponent = ({
  currentPrintPage,
  totalPrintPage,
  companyInfo,
  expenseType,
  statementDate,
}) => {
  return (
    <div>
      <BusinessInfo data={companyInfo} />

      <div className="flex justify-center items-center my-4">
        <p>
          <strong>Expense Statement</strong>
        </p>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <div className="grid grid-cols-4 gap-2">
            <div>
              <p>Expense ID:</p>
              <p>Expense Name:</p>
            </div>
            <div className="col-span-3">
              <p>{expenseType.code}</p>
              <p>{expenseType.name}</p>
            </div>
          </div>
        </div>
        <div />
        <div className="col-span-5">
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-1">
              <p>Date:</p>
              <p>Terms:</p>
              <p>Page:</p>
            </div>
            <div className="col-span-3">
              <p>{statementDate}</p>
              <p>{expenseType.terms || '-'}</p>
              <p>
                {currentPrintPage} of {totalPrintPage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DataTableComponent = ({ items, paging }) => {
  return (
    <DataTable
      rowKey="id"
      columns={[
        {
          title: 'Date',
          dataIndex: 'date',
          render: value => formatDate(value),
        },
        {
          title: 'Particular',
          dataIndex: 'item_code',
          render: (item_code, record) => (
            <>
              {record.id === 'forwarded_balance_item'
                ? item_code
                : `${formatFormalText(record.item_type)} ${item_code}`}
            </>
          ),
        },
        {
          title: 'Debit',
          dataIndex: 'debit',
          align: 'right',
          render: value => (value > 0 ? formatCurrency(value) : ''),
        },
        {
          title: 'Credit',
          dataIndex: 'credit',
          align: 'right',
          render: value => (value > 0 ? formatCurrency(value) : ''),
        },
        {
          title: 'Balance',
          dataIndex: 'balance',
          align: 'right',
          render: value => formatCurrency(value, { showZero: true }),
        },
      ]}
      dataSource={items}
      totalItems={paging?.total_items}
      currentPage={paging?.current_page || paging?.page}
      defaultCurrent={1}
      defaultPageSize={paging?.page_size || paging?.per_page}
      pageSize={paging?.page_size || paging?.per_page}
      size="small"
      pagingEnabled={!!paging}
    />
  );
};

const SummaryComponent = ({ summary, currentBalance, companyInfo }) => {
  return (
    <div className="flex flex-col flex-1">
      <div>
        <hr />
        <div className="flex items-start">
          <div className="mr-2">
            <p>RINGGIT MALAYSIA:</p>
          </div>
          <div className="flex-1">
            <p>{`${toWords.convert(currentBalance)}`.toUpperCase()}</p>
          </div>
          <div />
          <div className="ml-2 mr-4">
            <p className="text-right">
              {formatCurrency(currentBalance, { showZero: true })}
            </p>
          </div>
        </div>

        <DataTable
          rowKey="current"
          columns={[
            {
              title: 'Current',
              dataIndex: 'current_month',
              align: 'center',
              render: formatCurrency,
            },
            {
              title: '1 Month',
              dataIndex: 'first_month',
              align: 'center',
              render: formatCurrency,
            },
            {
              title: '2 Months',
              dataIndex: 'second_month',
              align: 'center',
              render: formatCurrency,
            },
            {
              title: '3 Months',
              dataIndex: 'third_month',
              align: 'center',
              render: formatCurrency,
            },
            {
              title: '4 Months',
              dataIndex: 'fourth_month',
              align: 'center',
              render: formatCurrency,
            },
            {
              title: 'Older',
              dataIndex: 'older',
              align: 'center',
              render: formatCurrency,
            },
          ]}
          dataSource={[summary]}
          totalItems={1}
          currentPage={1}
          defaultCurrent={1}
          size="small"
        />
        <div className="flex">
          <Text className="flex-2">
            We shall be gratefull if you will let us have payment as soon as
            possible. Any discrepancy in this statement must be reported to us
            in writing within 10 days.
          </Text>
          <BusinessStamp companyInfo={companyInfo} />
        </div>
      </div>
    </div>
  );
};

const useAllItemsQuery = ({ page, per_page, ...payload }) => {
  const {
    data: { pages } = { pages: [] },
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [statementApiCall.list.queryKey, payload, 'print'],
    queryFn: context =>
      statementApiCall.list.queryFn({
        ...payload,
        page: context.pageParam,
        per_page: 500,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, _lastPageParams, _allPageParams) => {
      if (lastPage.paging.page < lastPage.paging.page_count) {
        return lastPage.paging.page + 1;
      }
    },
  });

  React.useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [pages.length]);

  const allItems = React.useMemo(
    () =>
      flow(
        map(page => page.items),
        flatten
      )(pages),
    [pages.length]
  );

  return [
    allItems.sort(
      (item1, item2) =>
        moment(item1.date) - moment(item2.date) ||
        moment(item1.created) - moment(item2.created)
    ),
    hasNextPage,
  ];
};

const StatementItemDetail = ({ business_id, item: expenseType }) => {
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useFetchApiGet(businessApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const urlParams = useParams();
  const params = { business_id, status__in: '1001,1002' };
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading: isLoadingData } = useQuery({
    queryKey: [statementApiCall.list.queryKey, payload],
    queryFn: () => statementApiCall.list.queryFn(payload),
  });
  const { items = [], paging, stats } = data;
  const isLoading = isLoadingCompanyInfo || isLoadingData;

  const now = moment();
  const [printItems, stillFetchingPrintItems] = useAllItemsQuery(payload);

  React.useEffect(() => {
    if (printItems.some(item => item.id == 'forwarded_balance_item')) return;
    if (stillFetchingPrintItems) return;

    const firstItem = printItems[0];

    if (firstItem) {
      const forwaredBalanceItem = {
        ...firstItem,
        date: '',
        debit: '',
        credit: '',
        balance:
          firstItem.balance -
          Number(firstItem.debit) +
          Number(firstItem.credit),
        id: 'forwarded_balance_item',
        item_code: 'Balance Forward',
      };
      printItems.unshift(forwaredBalanceItem);
    }
  }, [stillFetchingPrintItems]);

  const statementDate =
    searchParams['date__gte'] || searchParams['date__lte']
      ? [
          formatDate(searchParams['date__gte']) || 'Beginning',
          formatDate(searchParams['date__lte']) || 'Present',
        ].join(' - ')
      : formatDate(now);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const { aging_balance: summary, balance: currentBalance } = stats;

  return (
    <Printable
      title="Statement"
      HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
        <HeaderComponent
          currentPrintPage={currentPrintPage}
          totalPrintPage={totalPrintPage}
          companyInfo={companyInfo}
          expenseType={expenseType}
          statementDate={statementDate}
        />
      )}
      DataTableComponent={DataTableComponent}
      SummaryComponent={() => (
        <SummaryComponent
          summary={summary}
          currentBalance={currentBalance}
          companyInfo={companyInfo}
        />
      )}
      items={items}
      printItems={printItems}
      paging={paging}
      chunkSize={12}
      readyToPrint={stillFetchingPrintItems === false}
    />
  );
};

export const ExpenseTypeStatementPrintPage = props => {
  const { pathname } = useLocation();

  return (
    <DetailPage
      title="Statement"
      apiCall={expenseTypeApiCall}
      ItemDetail={StatementItemDetail}
      resourceName="items"
      listUrl={pathname.replace('print', '')}
      {...props}
    />
  );
};
