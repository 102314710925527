export const statusColors = {
  1000: 'gray',
  1001: '#FFD700',
  1002: '#52c41a',
  1003: '#a9a9a9',
};

export const statusLabels = {
  1000: 'New',
  1001: 'Pending',
  1002: 'Completed',
  1003: 'Cancelled',
};

export const statusOptions = [
  {
    value: 1001,
    label: 'Pending',
  },
  {
    value: 1002,
    label: 'Completed',
  },
  {
    value: 1003,
    label: 'Cancelled',
  },
];

export const liteStatusOptions = [
  {
    value: 1002,
    label: 'Completed',
  },
  {
    value: 1003,
    label: 'Cancelled',
  },
];
