import { getTenantUrl } from 'utils/urlHelpers';

export function getItemLinkPrefix(businessId: string, itemType: string) {
  switch (itemType) {
    case 'expense':
      return getTenantUrl(businessId, '/expenses');
    case 'income':
      return getTenantUrl(businessId, '/incomes');
    case 'sales':
      return getTenantUrl(businessId, '/orders');
    case 'sales_return':
      return getTenantUrl(businessId, '/credit-notes');
    case 'credit_note':
      return getTenantUrl(businessId, '/credit-notes');
    case 'purchase':
      return getTenantUrl(businessId, '/purchases');
    case 'purchase_return':
      return getTenantUrl(businessId, '/purchase-returns');
    case 'payment':
      return getTenantUrl(businessId, '/payment-transactions');
    default:
      return '';
  }
}
