interface Business {
  id: number;
  permission: string;
}

export const processBusinessPermission = (businesses: Array<Business> | []) => {
  const permission = {};
  businesses.map(business => {
    permission[business.id] = business.permission;
  });
  return permission;
};
