import React from 'react';

const BusinessStamp = ({ companyInfo, showNote = true }) => {
  if (companyInfo.signature) {
    return (
      <div className="flex flex-col items-center justify-center">
        <img src={companyInfo.signature} height={80} />
        {companyInfo.stamp ? (
          <img src={companyInfo.stamp} height={100} className="absolute" />
        ) : null}
      </div>
    );
  }

  return companyInfo.stamp ? (
    <div
      className="flex flex-col items-center justify-center"
      style={{ minWidth: '50%' }}
    >
      <img src={companyInfo.stamp} height={100} />
      {showNote ? (
        <p className="text-center mt-2 uppercase">
          This is computer generated.
          <br /> No signature required.
        </p>
      ) : null}
    </div>
  ) : null;
};

export default BusinessStamp;
