import { Button, Space, notification } from 'antd';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { formatNumber } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';
import DataTable from 'common/ui/DataTable';
import XModal from 'components/XModal';
import deliveryOrderApiCall from 'apiCalls/deliveryOrder';
import deliveryOrderItemApiCall from 'apiCalls/deliveryOrderItem';
import useTenant from 'components/use-tenant';

const sharedTableColumns = ({ business_id }) => [
  {
    title: 'Product ID',
    dataIndex: 'product',
    render: product => product.code,
  },
  {
    title: 'Product Name',
    dataIndex: 'product_variant',
    render: (variant, record) => (
      <Link to={getTenantUrl(business_id, `/products/${record.product.id}`)}>
        {`${record.product?.name} - ${variant?.name}`}
      </Link>
    ),
  },
  {
    title: 'Particular',
    dataIndex: 'particular',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
    render: value => formatNumber(value),
  },
];

const actionColumnData = {
  title: 'Action',
  key: 'action',
};

const useDeleteDeliveryOrderItemMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deliveryOrderItemApiCall.delete?.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([deliveryOrderApiCall.detail.queryKey]);
      notification.open({ message: 'Deleted' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });
};

const DeliveryOrderItemDataTable = ({
  dataSource = [],
  renderEditItemForm,
}) => {
  const { business_id } = useTenant();
  const [deletingItemId, setDeletingItemId] = React.useState(null);

  const { mutate: deleteItem, isLoading: isDeleting } =
    useDeleteDeliveryOrderItemMutation();

  const getActionColumn = () => {
    return [
      {
        ...actionColumnData,
        render: (text, record) => {
          const onDelete = () => {
            setDeletingItemId(record.id);

            if (confirm('Are you sure?')) {
              deleteItem({ business_id, id: record.id });
            }
          };

          return (
            <Space>
              {renderEditItemForm && (
                <XModal
                  title="Edit"
                  onOk={() => {
                    reload();
                  }}
                >
                  {renderEditItemForm(record.id)}
                </XModal>
              )}
              <Button
                danger
                loading={isDeleting && record.id === deletingItemId}
                onClick={onDelete}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const columns = [
    ...sharedTableColumns({ business_id }),
    ...getActionColumn(),
  ];

  return (
    <DataTable
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      totalItems={dataSource.length}
      pagingEnabled={false}
    />
  );
};

export default DeliveryOrderItemDataTable;
