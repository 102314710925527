import { Button, Card, Divider, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {
  PhoneOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';

const BusinessCard = ({ business, user }) => {
  const isOwner = business.owner === user.id;
  const address = [
    business.address1, business.address2, business.address3
  ].filter(Boolean).join(', ');

  return (
    <Card className="shadow-md pb-0">
      <Link to={`/${business.id}`}>
        <div className="flex items-center justify-between">
          <div className="text-lg font-medium text-blue-600">{business.name}</div>
          {isOwner && <Tag color="green">Owner</Tag>}
        </div>
        <Divider className="my-4" orientation="left"></Divider>
      </Link>
      <div className="mt-2 space-y-2 text-gray-600">
        <div>
          <PhoneOutlined className="text-lg mr-1" />
          {business.phone || "-"}
        </div>
        <div>
          <EnvironmentOutlined className="text-lg mr-1" />
          {address || "-"}
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <Link to={`/${business.id}`}>
          <Button type="primary">Select</Button>
        </Link>
      </div>
    </Card>
  );
};

export default BusinessCard;
