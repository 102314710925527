import DataTable from 'common/ui/DataTable';

const InvoiceBody = ({ itemsData, columns }) => {
  return (
    <DataTable
      rowKey="id"
      columns={columns}
      dataSource={itemsData}
      totalItems={itemsData.length}
      currentPage={1}
      defaultCurrent={1}
      size="small"
    />
  );
};

export default InvoiceBody;
