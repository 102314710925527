import { useQuery } from '@tanstack/react-query';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { getOr, isEmpty, isNull, some } from 'lodash/fp';
import { useMemo, useState } from 'react';

export const mergeOptions = (options, initialOption, fieldName = 'value') => {
  if (isEmpty(initialOption)) {
    return options;
  }

  if (isNull(getOr(null, fieldName)(initialOption))) {
    return options;
  }

  if (some(initialOption)(options)) {
    return options;
  }

  // check if the fieldName's value is already in the options
  if (some({ [fieldName]: getOr(null, fieldName)(initialOption) })(options)) {
    return options;
  }

  return [initialOption, ...options];
};

const DebounceSelect = ({
  apiCall,
  debounceTimeout = 800,
  displayFn,
  params = {},
  fieldNames = {},
  onSelect,

  initialOption = {}, // this for long list of options which not able to contain the selected initial option.
  value,
  ...props
}) => {
  const [search, setSearch] = useState();
  const debounceSearch = useMemo(
    () => debounce(setSearch, debounceTimeout),
    [setSearch, debounceTimeout]
  );
  const { data, isLoading } = useQuery({
    queryKey: [apiCall.queryKey, { ...params, search }],
    queryFn: () => apiCall.queryFn({ ...params, search }),
  });

  const options = getOr([], 'items')(data);

  // make sure it won't duplicate the initial option in most of the case that the initial option is already
  // in the options. Only add the initial option if it's not in the options.
  // it happens when the list is paging and the initial option is not in the current page.
  const allOptions = mergeOptions(options, initialOption, fieldNames.value);

  return (
    <Select
      showSearch={true}
      loading={isLoading}
      onSearch={debounceSearch}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      options={displayFn ? displayFn(allOptions) : allOptions}
      allowClear
      onClear={() => {
        setSearch('');
      }}
      onSelect={(value, option) => {
        onSelect && onSelect(value, option);
      }}
      fieldNames={{
        label: 'label',
        value: 'value',
        options: 'options',
        groupLabel: 'label',
        ...fieldNames,
      }}
      value={!isLoading ? value : null}
      {...props}
    />
  );
};

export default DebounceSelect;
