import logo from 'assets/icons/duitbooks.svg';
import { Link } from 'react-router-dom';

const Logo = () => (
  <Link to="/">
    <img src={logo} alt="DuitBooks" className="h-8" />
  </Link>
);

export default Logo;
