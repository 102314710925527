import { Button } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import React from 'react';

import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import apiCall from 'apiCalls/expense';
import useTenant from 'components/use-tenant';

import { columnsGenerator } from './config';
import { getTenantUrl } from '../../utils/urlHelpers';
import InvoiceItemDetail from './invoice';
import ItemDetail from './detail';
import ItemForm from './forms/ExpenseForm';

export const ExpenseListPage = () => {
  const { business_id } = useTenant();
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const isCompletedPage = searchParams.status === '1002';

  return (
    <ListPage
      title="All Expenses"
      namespace="expenses"
      searchOptions={{ tooltip: 'Enter invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'branches'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
      extraBtns={
        isCompletedPage ? (
          <Link
            to={getTenantUrl(
              business_id,
              `/expenses/statement?${baseSearchParams.toString()}`
            )}
          >
            <Button type="primary">Statement</Button>
          </Link>
        ) : null
      }
      {...(isCompletedPage ? { createUrl: '' } : {})}
    />
  );
};

export const ExpenseDetailPage = () => (
  <DetailPage
    title="Expense Detail"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const ExpenseEditPage = () => (
  <EditPage
    title="Expense"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export const ExpenseCreatePage = () => (
  <CreatePage
    title="Expenses"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export const ExpenseInvoicePage = () => (
  <DetailPage
    title="Invoice"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
  />
);

export { StatementPage as ExpenseStatementPage } from './statement';
