import { ToWords } from 'to-words';

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: '',
      plural: '',
      symbol: 'MYR',
      fractionalUnit: {
        name: 'cent',
        plural: 'cents',
        symbol: '',
      },
    },
  },
});

export default toWords;
