import { Card, Descriptions, Typography } from 'antd';
import { formatDateTime } from 'utils/dateUtils';

const { Item: DescItem } = Descriptions;
const { Title } = Typography;

const ItemDetail = ({ extraActions, item }) => {
  return (
    <div className="lg:w-2/3 w-full justify-center">
      <div className="flex flex-col py-4">
        <Card
          title={<Title level={3}>Account Detail</Title>}
          extra={extraActions}
        >
          <Descriptions column={1} bordered>
            <DescItem label="Company Name">{item.name}</DescItem>
            <DescItem label="Tax Identification Number (TIN)">
              {item.tax_number}
            </DescItem>
            <DescItem label="Registration Number (SSM number)">
              {item.registration_number}
            </DescItem>
            {item.registration_number_old && (
              <DescItem label="SSM number (old)">
                {item.registration_number_old}
              </DescItem>
            )}
            <DescItem label="MSIC Code">{item.msic_code}</DescItem>
            <DescItem label="Business Activity">
              <div
                dangerouslySetInnerHTML={{ __html: item.business_activity }}
              />
            </DescItem>
            <DescItem label="Person In Charge">
              {item.person_in_charge}
            </DescItem>
            <DescItem label="Phone">{item.phone}</DescItem>
            <DescItem label="Email">{item.email}</DescItem>
            <DescItem label="Address line 1">{item.address1}</DescItem>
            <DescItem label="Address line 2">{item.address2}</DescItem>
            <DescItem label="Address line 3">{item.address3}</DescItem>
            <DescItem label="Terms">{item.terms}</DescItem>
            <DescItem label="Bank Name">{item.bank_name}</DescItem>
            <DescItem label="Bank Account Number">
              {item.bank_account_number}
            </DescItem>
            <DescItem label="Director's Name & IC">
              <div dangerouslySetInnerHTML={{ __html: item.director_name }} />
            </DescItem>
            <DescItem label="Admin Notes">
              <div dangerouslySetInnerHTML={{ __html: item.admin_notes }} />
            </DescItem>
            <DescItem label="Joined At">
              {formatDateTime(item.date_joined)}
            </DescItem>
          </Descriptions>
        </Card>
      </div>
    </div>
  );
};

export default ItemDetail;
