import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

const useFormBlocker = form => {
  const [shouldBlock, setShouldBlock] = useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldBlock && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (confirm('Change you made may not be saved.') == true) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    } else {
    }
  }, [blocker.state]);

  useEffect(() => {
    const handleBeforeUnload = e => {
      if (shouldBlock && form.isFieldsTouched()) {
        e.preventDefault();
        e.returnValue = ''; // This is required for Chrome to show the warning dialog.
      }
    };

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [form, shouldBlock]);

  return setShouldBlock;
};

export default useFormBlocker;
