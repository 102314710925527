import { Descriptions } from 'antd';
import React, { useEffect } from 'react';

import { businessLayout } from 'constants/invoice';
import { formatDate } from 'utils/dateUtils';
import { useFetchApiGet } from 'common/reduxutils';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import InvoiceBody from 'components/Invoice/InvoiceBody';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import Printable from 'components/Printable';
import Square from 'components/Invoice/Square';
import UserInfo from 'components/Invoice/UserInfo';
import apiCall from 'apiCalls/business';

import { formatDateTime } from '../../utils/dateUtils';
import BusinessStamp from '../../common/ui/BusinessStamp';

const InvoiceItemDetail = ({ business_id, item }) => {
  const {
    data: companyInfo,
    load: fetchBusinessInfo,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchBusinessInfo({ id: business_id });
  }, [business_id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Printable
        title={`Delivery Order ${item.code}`}
        HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
          <>
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <BusinessInfo data={companyInfo} />
              </div>
              <div className="flex flex-col items-center">
                <Square invoiceName="Delivery order" />
                <p className="mt-2">
                  <b>DELIVERY ORDER NO: {item.code}</b>
                </p>
              </div>
            </div>

            <hr className="my-5" />

            <UserInfo
              user={item.user}
              variant={businessLayout.DETAILED}
              paymentAccountDisplayName="Cash Sales Delivery order"
              invoiceData={item}
            >
              <div className="grid grid-cols-3 gap-2">
                <div>
                  <p>
                    <b>Date:</b>
                  </p>
                  <p>
                    <b>Reference No:</b>
                  </p>
                  <p>
                    <b>Branch:</b>
                  </p>
                  {totalPrintPage > 1 ? (
                    <p>
                      <b>Page:</b>
                    </p>
                  ) : null}
                </div>
                <div className="col-span-2">
                  <p>{formatDate(item.date)}</p>
                  <p>{item.reference_number || '-'}</p>
                  <p>{item.branch?.name || '-'}</p>
                  {totalPrintPage > 1 ? (
                    <p>
                      {currentPrintPage} of {totalPrintPage}
                    </p>
                  ) : null}
                </div>
              </div>
            </UserInfo>
          </>
        )}
        DataTableComponent={({ items }) => (
          <InvoiceBody
            itemsData={items}
            columns={[
              {
                title: 'Product ID',
                dataIndex: 'product',
                render: product => product.code,
              },
              {
                title: 'Product Name',
                dataIndex: 'product_variant',
                render: (variant, record) =>
                  `${record.product?.name} - ${variant?.name}`,
              },
              {
                title: 'Particular',
                dataIndex: 'particular',
                render: text => (
                  <div
                    style={{
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '50vw', // Restricts column width to 50% of viewport width
                    }}
                  >
                    {text}
                  </div>
                ),
              },
              {
                title: 'Unit',
                dataIndex: 'product',
                render: product => product.unit,
                align: 'right',
              },
              {
                title: 'Quantity',
                dataIndex: 'quantity',
                align: 'center',
              },
            ]}
          />
        )}
        SummaryComponent={() => (
          <div>
            {item.notes && (
              <p className="my-3">
                <strong>Notes: </strong>
                {item.notes}
              </p>
            )}
            <hr className="my-1" />

            <div className="grid grid-cols-3 gap-2">
              <div className="col-span-2">
                <p className="my-2 uppercase text-sm">
                  Thank you for your order! Please check all items carefully and
                  notify us of any discrepancy within 24 hours
                </p>
                {item.bank_notes && (
                  <div className="mb-2 leading-5 footer">
                    {item.bank_notes.split('\n').map(note => {
                      if (note.includes('Bank Account')) {
                        return <p className="semibold">{note}</p>;
                      }

                      return <p>{note}</p>;
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3 my-1">
              <div>
                <p className="mb-4">
                  <strong>AUTHORISED SIGNATURE</strong>
                </p>
                <div
                  className="flex items-start items-center"
                  style={{ height: 100 }}
                >
                  <BusinessStamp companyInfo={companyInfo} showNote={false} />
                </div>
              </div>
              <div>
                <p className="mb-4">
                  <strong>AUTHORISED SIGNATURE</strong>
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className="">
                <hr className="my-3 ml-0 w-1/2" />
                <h4 className="m-0 mt-2">
                  <strong className="uppercase">
                    {companyInfo.company_name}
                    {companyInfo.registration_number
                      ? ` (${companyInfo.registration_number})`
                      : ''}
                  </strong>
                </h4>

                {companyInfo.signature ? null : (
                  <p className="m-0 uppercase">
                    This is computer generated. No signature required.
                  </p>
                )}
              </div>
              <div className="">
                <hr className="my-3 ml-0 w-1/2" />
                <h4 className="m-0 mt-2">
                  <strong>CUSTOMER COMPANY STAMP AND SIGNATURE</strong>
                </h4>
                <p className="m-0">
                  <b>NAME:</b>
                </p>
                <p className="m-0">
                  <b>I/C NUMBER:</b>
                </p>
              </div>
            </div>
          </div>
        )}
        items={item.delivery_order_items}
        chunkSize={8}
      />
      <Descriptions column={1} bordered className="my-5">
        <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
        <Descriptions.Item label="Personal Notes">
          {item.notes_admin}
        </Descriptions.Item>
        <Descriptions.Item label="Attachment">
          {item.file ? (
            <a href={item.file} target="_blank" rel="noreferrer">
              Open Attachment
            </a>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {formatDateTime(item.created)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated Date">
          {formatDateTime(item.modified)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default InvoiceItemDetail;
