import apiCall from 'apiCalls/paymentAccount';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const PaymentAccountListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      searchOptions={{ tooltip: false }}
      filterOptions={{ filters: ['date'] }}
      title="Cash & Bank"
      namespace="payment-accounts"
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const PaymentAccountDetailPage = props => (
  <DetailPage
    title="Cash & Bank"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
    options={{ deleteEnabled: true }}
    namespace="payment-accounts"
    {...props}
  />
);

export const PaymentAccountEditPage = props => (
  <EditPage
    title="Cash & Bank"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const PaymentAccountCreatePage = props => (
  <CreatePage
    title="Cash & Bank"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
