import { StarFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/dateUtils';
import { getTenantUrl } from 'utils/urlHelpers';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'Ordering',
    dataIndex: 'ordering',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (value, record) => {
      if (!record.is_featured) return value;
      return (
        <>
          <StarFilled spin style={{ color: '#ffec3d', border: '' }} /> {value}
        </>
      );
    },
  },
  // {
  // title: 'Created',
  // dataIndex: 'created',
  // render: value => formatDateTime(value),
  // },
  // {
  // title: 'Modified',
  // dataIndex: 'modified',
  // render: value => formatDateTime(value),
  // },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={getTenantUrl(business_id, `/branches/${record.id}/edit`)}>
            Edit
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];
