import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const BreadCrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split('/').filter(item => item);
  const capatilize = s => s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <Breadcrumb
      items={pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;

        return isLast
          ? { title: capatilize(name) }
          : {
              title: (
                <Link to={`${routeTo}`}>
                  {capatilize(name.replace('-', ' '))}
                </Link>
              ),
            };
      })}
    ></Breadcrumb>
  );
};

export default BreadCrumb;
