import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_DELIVERY_ORDER_ITEM_CREATE_API_ID =
  'FETCH_DELIVERY_ORDER_ITEM_CREATE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_DELIVERY_ORDER_ITEM_CREATE_API_ID,
  ({ business_id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/delivery-order-items`,
    }),
    method: 'POST',
    body: payload,
  })
);

export default apiCall;
