import customerApiCall from './customer';
import listApiCall from './list';
import supplierApiCall from './supplier';

const ApiCall = {
  list: listApiCall,
  customer: customerApiCall,
  supplier: supplierApiCall,
};

export default ApiCall;
