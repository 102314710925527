import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

export const DELETE_INCOME_ITEM_API_ID = 'DELETE_INCOME_ITEM_API_ID';

const apiCall = enhancedMakeFetchAction(
  DELETE_INCOME_ITEM_API_ID,
  ({ business_id, id }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/income-items/${id}`,
    }),
    method: 'DELETE',
    notify: true,
    isStay: true,
  })
);

export default apiCall;
