import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_INCOME_TYPE_STATEMENT_API =
  'INCOME/FETCH_INCOME_TYPE_STATEMENT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INCOME_TYPE_STATEMENT_API,
  ({ id, business_id, ...params }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/income-types/${id}/statement`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
