import { Button, Card, Descriptions, Space, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

import { accountTypes } from 'constants/invoice';
import { formatDateTime } from 'utils/dateUtils';
import { getTenantUrl } from 'utils/urlHelpers';
import { orderStatusColors, orderStatusLabels } from 'constants/order';

import OrderItemDataTable from './OrderItemDataTable';

const { Title } = Typography;

const ItemDetail = ({ business_id, item }) => {
  const isFromPaymentAccount = item.user?.account_type === accountTypes.PAYMENT;

  return (
    <>
      <Card
        title={<Title level={3}>Order Detail</Title>}
        extra={
          <Space>
            <Button disabled={item.status == 1002 || item.status == 1003}>
              <Link to={getTenantUrl(business_id, `/orders/${item.id}/edit`)}>
                Edit
              </Link>
            </Button>
            <Button>
              <Link
                to={getTenantUrl(business_id, `/orders/${item.id}/invoice`)}
              >
                View invoice
              </Link>
            </Button>
          </Space>
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Status">
            <Tag color={orderStatusColors[item.status]}>
              {orderStatusLabels[item.status]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Customer">
            {item.user &&
              (isFromPaymentAccount ? (
                item.user.name
              ) : (
                <Link
                  to={getTenantUrl(business_id, `/customers/${item.user.id}`)}
                >
                  {item.user.name}
                </Link>
              ))}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address Line 1">
            {item.billing_address}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address Line 2">
            {item.billing_address2}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address Line 3">
            {item.billing_address3}
          </Descriptions.Item>

          <Descriptions.Item label="Contact Person">
            {item.contact_person}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {item.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {item.customer_email}
          </Descriptions.Item>

          <Descriptions.Item label="Shipping Address">
            {item.shipping_address}
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Contact">
            {item.shipping_name}
          </Descriptions.Item>
          <Descriptions.Item label="Shipping Phone Number">
            {item.shipping_phone_number}
          </Descriptions.Item>

          <Descriptions.Item label="Invoice No.">{item.code}</Descriptions.Item>
          <Descriptions.Item label="Date">{item.date}</Descriptions.Item>
          <Descriptions.Item label="Branch">
            {item.branch?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Reference No.">
            {item.reference_number}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card title={<Title level={3}>Order Items</Title>} className="my-5">
        <OrderItemDataTable dataSource={item.order_items} order={item} />
      </Card>

      <Descriptions column={1} bordered className="my-5">
        <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
        <Descriptions.Item label="Personal Notes">
          {item.notes_admin}
        </Descriptions.Item>
        <Descriptions.Item label="Attachment">
          {item.file ? (
            <a href={item.file} target="_blank" rel="noreferrer">
              Open Attachment
            </a>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {formatDateTime(item.created)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated Date">
          {formatDateTime(item.modified)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ItemDetail;
