import { useMutation } from '@tanstack/react-query';
import { Button, Space, notification } from 'antd';
import { useFetchApiGet } from 'common/reduxutils';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import useTenant from 'components/use-tenant';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getTenantUrl } from 'utils/urlHelpers';

const AdminDetail = ({
  namespace,
  listUrl,
  apiCall,
  options = {},
  ItemDetail,
  resourceName,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { business_id } = useTenant();
  const { title = 'View detail', deleteEnabled = false, id } = options;
  const itemId = id || params.id;
  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(apiCall.detail, { resourceName: resourceName });

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: apiCall.delete?.queryFn,
    onSuccess: () => {
      notification.open({
        type: 'success',
        message: 'Success',
      });
      navigate(-1);
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });

  const onDelete = () => {
    if (confirm('Are you sure?')) {
      deleteItem({ business_id, id: itemId });
    }
  };

  useEffect(() => {
    fetchItem({ business_id, id: itemId });
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  return (
    <>
      <PageTitle title={title} backUrl={listUrl} />
      <ItemDetail
        item={item}
        business_id={business_id}
        apiCall={apiCall}
        extraActions={
          <Space>
            <Button type="primary">
              <Link
                to={getTenantUrl(business_id, `/${namespace}/${item.id}/edit`)}
              >
                Edit
              </Link>
            </Button>
            {deleteEnabled && (
              <Button danger loading={isDeleting} onClick={onDelete}>
                Delete
              </Button>
            )}
          </Space>
        }
      />
    </>
  );
};

export default AdminDetail;
