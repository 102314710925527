import apiCall from 'apiCalls/deliveryOrder';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import DeliveryOrderForm from './forms/DeliveryOrderForm';
import InvoiceItemDetail from './invoice';

export const DeliveryOrderListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Delivery Orders"
      namespace="delivery-orders"
      searchOptions={{ tooltip: 'Search by invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'users'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const DeliveryOrderDetailPage = props => (
  <DetailPage
    title="Delivery Order Detail"
    namespace="delivery-orders"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const DeliveryOrderEditPage = props => (
  <EditPage
    title="Delivery Order Edit"
    namespace="delivery-orders"
    apiCall={apiCall}
    ItemForm={DeliveryOrderForm}
    resourceName=""
    {...props}
  />
);

export const DeliveryOrderCreatePage = props => (
  <CreatePage
    title="Delivery Order Create"
    namespace="delivery-orders"
    apiCall={apiCall}
    ItemForm={DeliveryOrderForm}
    resourceName=""
    {...props}
  />
);

export const DeliveryOrderInvoicePage = props => (
  <DetailPage
    title="Delivery Order Invoice"
    namespace="delivery-orders"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);
