import { Divider } from 'antd';
import useAdminAuth from 'hooks/useAdminAuth';
import Logo from './Logo';
import UserMenu from './UserMenu';

const HeaderMain = () => {
  const { user, isAuthenticated, loading, signOut } = useAdminAuth();

  return (
    <header className="flex items-center justify-between px-6 py-3 bg-background border-b">
      <div className="flex items-center space-x-4">
        <Logo />
        <Divider type="vertical" />
      </div>
      <div className="flex items-center space-x-4">
        <span className="text-sm text-muted-foreground">
          {loading ? (
            '..'
          ) : isAuthenticated ? (
            <UserMenu user={user} signOut={signOut} />
          ) : null}
        </span>
      </div>
    </header>
  );
};

export default HeaderMain;
