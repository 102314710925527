import React from 'react';

import { formatCurrency } from 'utils/formatAmount';
import BusinessStamp from 'common/ui/BusinessStamp';

const Notes = ({ notes }) => (
  <div className="flex-1">
    {notes && (
      <>
        <p>
          <b>Notes: </b>
          {notes}
        </p>
      </>
    )}
  </div>
);

const Footer = ({ item, bankNotes, companyInfo }) => {
  return (
    <div>
      {bankNotes ? <Notes notes={item.notes} /> : null}

      <hr className="my-3" />

      <div className="grid grid-cols-2 gap-2">
        <div>
          {bankNotes ? (
            bankNotes.split('\n').map(note => {
              if (note.includes('Bank Account')) {
                return (
                  <p>
                    <b>{note}</b>
                  </p>
                );
              }

              return <p>{note}</p>;
            })
          ) : (
            <Notes notes={item.notes} />
          )}
        </div>
        <div className="flex gap-16 justify-end leading-1">
          <div>
            <p>
              <b>Sub Total:</b>
            </p>
            <p>
              <b>Tax:</b>
            </p>
            <p>
              <b>Discount Given:</b>
            </p>
            <p>
              <b>Rounding Adjustment:</b>
            </p>
            <p>
              <b>Total Amount:</b>
            </p>
          </div>
          <div className="pr-5">
            <p>
              <b>{formatCurrency(item.sub_total)}</b>
            </p>
            <p>
              <b>{formatCurrency(item.tax)}</b>
            </p>
            <p>
              <b>{formatCurrency(item.discount)}</b>
            </p>
            <p>
              <b>{formatCurrency(item.rounding_adjustment)}</b>
            </p>
            <p>
              <b>{formatCurrency(item.total_amount)}</b>
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-2">
        <div>
          <div className="flex items-start">
            <BusinessStamp companyInfo={companyInfo} />
          </div>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};

export default Footer;
