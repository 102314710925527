import apiCall from 'apiCalls/customer';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { useParams } from 'react-router-dom';
import { getTenantUrl } from 'utils/urlHelpers';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const UserListPage = props => {
  const { business_id } = useTenant();
  return (
    <ListPage
      namespace="customers"
      searchOptions={{ tooltip: 'Enter name, email or phone number' }}
      title="All Customers"
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
      createUrl={getTenantUrl(business_id, `/customers/create`)}
      {...props}
    />
  );
};

export const UserEditPage = props => {
  const { business_id } = useTenant();
  const { id } = useParams();

  return (
    <EditPage
      namespace="customers"
      apiCall={apiCall}
      listUrl={getTenantUrl(business_id, `/customers/${id}`)}
      ItemForm={ItemForm}
      title="Edit customer"
      resourceName=""
      {...props}
    />
  );
};

export const UserDetailPage = (props, { params }) => {
  const { business_id } = useTenant();
  return (
    <DetailPage
      namespace="customers"
      apiCall={apiCall}
      listUrl={getTenantUrl(business_id, `/customers`)}
      ItemDetail={ItemDetail}
      resourceName=""
      options={{ deleteEnabled: true }}
      {...props}
    />
  );
};

export const UserCreatePage = props => {
  const { business_id } = useTenant();
  return (
    <CreatePage
      namespace="customers"
      title="Customers"
      apiCall={apiCall}
      listUrl={getTenantUrl(business_id, `/customers`)}
      ItemForm={ItemForm}
      resourceName=""
      {...props}
    />
  );
};
