import { flow, map, flatten } from 'lodash/fp';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import moment from 'moment';

import { DetailPage } from 'common/ui/AdminCRUD/page';
import { formatCurrency, formatNumber } from 'utils/formatAmount';
import { formatDate } from 'utils/dateUtils';
import { useFetchApiGet } from 'common/reduxutils';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import DataTable from 'common/ui/DataTable';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import Printable from 'components/Printable';
import businessApiCall from 'apiCalls/business';
import inventoryEntryApiCall from 'apiCalls/inventoryEntry';
import productVariantApiCall from 'apiCalls/productVariant';
import useTenant from 'components/use-tenant';

import { inventoryStatementColumns } from './config';

const HeaderComponent = ({
  currentPrintPage,
  totalPrintPage,
  companyInfo,
  productVariant,
  statementDate,
}) => {
  return (
    <div>
      <BusinessInfo data={companyInfo} />

      <div className="flex justify-center items-center my-4">
        <p>
          <strong>{productVariant.product?.name} Statement</strong>
        </p>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <div className="grid grid-cols-5 gap-2">
            <div className="col-span-2">
              <p>Product Name:</p>
              <p>Product Code:</p>
              <p>Variant ID:</p>
              <p>Variant Name:</p>
            </div>
            <div className="col-span-3">
              <p>{productVariant.product?.name}</p>
              <p>{productVariant.product?.code}</p>
              <p>{productVariant.code}</p>
              <p>{productVariant.name}</p>
            </div>
          </div>
        </div>
        <div />
        <div className="col-span-5">
          <div className="grid grid-cols-4 gap-2">
            <div className="col-span-1">
              <p>Date:</p>
              <p>Page:</p>
            </div>
            <div className="col-span-3">
              <p>{statementDate}</p>
              <p>
                {currentPrintPage} of {totalPrintPage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DataTableComponent = ({ items, paging }) => {
  const { business_id } = useTenant();

  return (
    <DataTable
      rowKey="id"
      columns={inventoryStatementColumns({ business_id })}
      dataSource={items}
      totalItems={paging?.total_items}
      currentPage={paging?.current_page || paging?.page}
      defaultCurrent={1}
      defaultPageSize={paging?.page_size || paging?.per_page}
      pageSize={paging?.page_size || paging?.per_page}
      size="small"
      pagingEnabled={!!paging}
    />
  );
};

const SummaryComponent = ({ metaData }) => {
  const { balance, avg_purchase_price, inventory_value } = metaData;

  return (
    <div className="flex flex-col flex-1">
      <div>
        <hr />
        <div className="flex items-start">
          <div className="mr-2">
            <p>Averate Purchase Price:</p>
          </div>
          <div className="flex-1">
            <p>{formatCurrency(avg_purchase_price)}</p>
          </div>
          <div />
          <div className="ml-2 mr-4">
            <p className="text-right">Stock Balance: {formatNumber(balance)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const useAllItemsQuery = ({ page, per_page, ...payload }) => {
  const {
    data: { pages } = { pages: [] },
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [inventoryEntryApiCall.list.queryKey, payload, 'print'],
    queryFn: context =>
      inventoryEntryApiCall.list.queryFn({
        ...payload,
        page: context.pageParam,
        per_page: 500,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, _lastPageParams, _allPageParams) => {
      if (lastPage.paging.page < lastPage.paging.page_count) {
        return lastPage.paging.page + 1;
      }
    },
  });

  React.useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [pages.length]);

  const allItems = React.useMemo(
    () =>
      flow(
        map(page => page.items),
        flatten
      )(pages),
    [pages.length]
  );

  return [
    allItems.sort(
      (item1, item2) =>
        moment(item1.date) - moment(item2.date) ||
        moment(item1.created) - moment(item2.created)
    ),
    hasNextPage,
  ];
};

const StatementItemDetail = ({ business_id, item: productVariant }) => {
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useFetchApiGet(businessApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const urlParams = useParams();
  const params = { business_id, status__in: '1001,1002' };
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading: isLoadingData } = useQuery({
    queryKey: [inventoryEntryApiCall.list.queryKey, payload],
    queryFn: () => inventoryEntryApiCall.list.queryFn(payload),
  });
  const { items = [], paging, meta, stats } = data;
  const isLoading = isLoadingCompanyInfo || isLoadingData;

  const [printItems, stillFetchingPrintItems] = useAllItemsQuery(payload);
  const metaData = { ...stats, ...meta?.stats };
  const now = moment();
  const statementDate =
    searchParams['date__gte'] || searchParams['date__lte']
      ? [
          formatDate(searchParams['date__gte']) || 'Beginning',
          formatDate(searchParams['date__lte']) || 'Present',
        ].join(' - ')
      : formatDate(now);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Printable
      title="Statement"
      HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
        <HeaderComponent
          currentPrintPage={currentPrintPage}
          totalPrintPage={totalPrintPage}
          companyInfo={companyInfo}
          productVariant={productVariant}
          statementDate={statementDate}
        />
      )}
      DataTableComponent={DataTableComponent}
      SummaryComponent={() => <SummaryComponent metaData={metaData} />}
      items={items}
      printItems={printItems}
      paging={paging}
      chunkSize={8}
      readyToPrint={stillFetchingPrintItems === false}
    />
  );
};

export const StatementPrintPage = props => {
  const { product_variant_id } = useParams();
  const { pathname } = useLocation();

  return (
    <DetailPage
      title="Statement"
      apiCall={productVariantApiCall}
      ItemDetail={StatementItemDetail}
      resourceName="items"
      options={{ id: product_variant_id }}
      listUrl={pathname.replace('statement', '')}
      {...props}
    />
  );
};
