import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_INCOME_TYPE_DETAIL_API_ID = 'FETCH_INCOME_TYPE_DETAIL_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_INCOME_TYPE_DETAIL_API_ID,
  ({ business_id, id }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/income-types/${id}`,
    }),
    method: 'GET',
  })
);

export default apiCall;
