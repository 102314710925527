import apiCall from 'apiCalls/expenseType';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const ExpenseTypeListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Expense Types"
      namespace="expense-types"
      searchOptions={{ tooltip: false }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const ExpenseTypeDetailPage = () => (
  <DetailPage
    title="Expense Detail"
    namespace="expense-types"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const ExpenseTypeEditPage = () => (
  <EditPage
    title="Expense Type"
    namespace="expense-types"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export const ExpenseTypeCreatePage = () => (
  <CreatePage
    title="Expense Types"
    namespace="expense-types"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export { ExpenseTypeStatementPage } from './statement';
export { ExpenseTypeStatementPrintPage } from './statementPrint';
