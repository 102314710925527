import { Table } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { formatTableColumns } from './config';

const DataTable = ({
  dataFetcher,
  dataSource,
  currentPage,
  pagingEnabled = true,
  totalItems,
  filters,
  loading,
  pageSize,
  rowKey,
  columns,
  ...props
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {};
    for (let entry of searchParams.entries()) {
      params[entry[0]] = entry[1];
    }

    if (sorter.order == 'ascend' || sorter.order == 'descend') {
      setSearchParams({
        ...params,
        ...filters,
        page: pagination.current,
        ordering: sorter.order == 'ascend' ? sorter.field : `-${sorter.field}`,
      });
    } else {
      setSearchParams({
        ...params,
        ...filters,
        page: pagination.current,
      });
    }
  };

  const pagingOptions = {
    position: 'both',
    defaultCurrent: 1,
    current: currentPage,
    total: totalItems,
    hideOnSinglePage: true,
    pageSize: pageSize,
    showSizeChanger: false,
  };

  return (
    <Table
      style={{ marginTop: 20, marginBottom: 20 }}
      rowKey={rowKey || 'id'}
      bordered
      dataSource={dataSource}
      columns={formatTableColumns(columns)}
      loading={loading}
      onChange={handleTableChange}
      pagination={pagingEnabled ? pagingOptions : false}
      {...props}
    />
  );
};

export default DataTable;
