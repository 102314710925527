import { businessLayout } from 'constants/invoice';

const BusinessInfo = ({ data, variant = businessLayout.SIMPLE }) => {
  return (
    <div className="flex items-center">
      {data.logo ? <img src={data.logo} height={100} /> : null}
      <div className="ml-4">
        <p className="">
          <b>
            {data.company_name}
            {data.registration_number && ` (${data.registration_number})`}
          </b>
        </p>
        <div className="mt-1">
          <p>
            <b>{data.address1}</b>
          </p>
          <p>
            <b>{data.address2}</b>
          </p>
          <p>
            <b>{data.address3}</b>
          </p>
          {variant === businessLayout.SIMPLE && (
            <>
              {data.phone && (
                <p>
                  <b>Tel: {data.phone}</b>
                </p>
              )}
              {data.email && (
                <p>
                  <b>Email: {data.email}</b>
                </p>
              )}
            </>
          )}
        </div>
        {variant === businessLayout.DETAILED && (
          <div className="flex gap-4 my-2">
            <div>
              <p>Phone</p>
              <p>Email</p>
              <p>TIN</p>
            </div>
            <div className="col-span-2">
              <p>: {data.phone || '-'}</p>
              <p>: {data.email || '-'}</p>
              <p>: {data.tax_number || '-'}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessInfo;
