import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const PURCHASE_ITEM_CREATE_API_ID = 'PURCHASE_ITEM_CREATE_API_ID';

const apiCall = enhancedMakeFetchAction(
  PURCHASE_ITEM_CREATE_API_ID,
  ({ business_id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/purchase-items`,
    }),
    method: 'POST',
    body: payload,
  })
);

export default apiCall;
