import { Typography, Space } from 'antd';
import React from 'react';

const { Title } = Typography;

const Square = ({ invoiceName }) => (
  <div className="border-1 border-black border-solid p-2">
    <Space direction="vertical" className="uppercase font-bold" size={0}>
      <Title level={3} className="!m-0">
        {invoiceName}
      </Title>
    </Space>
  </div>
);

export default Square;
