import { Button } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import apiCall from 'apiCalls/purchaseReturn';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { columnsGenerator } from './config';
import { getTenantUrl } from '../../utils/urlHelpers';
import ItemDetail from './detail';
import PurchaseReturnForm from './forms/PurchaseReturnForm';
import InvoiceItemDetail from './invoice';

export const PurchaseReturnListPage = () => {
  const { business_id } = useTenant();
  const [baseSearchParams] = useSearchParams();

  return (
    <ListPage
      title="All Purchase Returns"
      namespace="purchase-returns"
      searchOptions={{ tooltip: 'Search by invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'users'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
      extraBtns={
        <Button type="primary">
          <Link
            to={getTenantUrl(
              business_id,
              `/purchase-returns/statement?${baseSearchParams.toString()}`
            )}
          >
            Statement
          </Link>
        </Button>
      }
    />
  );
};

export const PurchaseReturnDetailPage = props => (
  <DetailPage
    title="Purchase Return Details"
    namespace="purchase-returns"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const PurchaseReturnInvoicePage = props => (
  <DetailPage
    title="Purchase Return Invoice"
    namespace="purchase-returns"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);

export const PurchaseReturnEditPage = props => (
  <EditPage
    title="Edit Purchase Return"
    namespace="purchase-returns"
    apiCall={apiCall}
    ItemForm={PurchaseReturnForm}
    resourceName=""
    {...props}
  />
);

export const PurchaseReturnCreatePage = props => (
  <CreatePage
    title="New Purchase Return"
    namespace="credit-notes"
    apiCall={apiCall}
    ItemForm={PurchaseReturnForm}
    resourceName=""
    {...props}
  />
);

export { StatementPage as PurchaseReturnStatementPage } from './statement';
