import { useQuery } from '@tanstack/react-query';
import { Button, Form } from 'antd';
import DataTable from 'common/ui/DataTable';
import PageTitle from 'components/PageTitle';
import useTenant from 'components/use-tenant';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

const AdminList = ({
  title,
  createLabel,
  namespace,
  columnGenerator,
  apiCall,
  createUrl,
  listProps,
  resourceName,
  FilterForm,
  showBreadscrumb = true,
  extra,
  params,
  apiCallSuffix,
  urlParamEnabled = true,
  queryParamEnabled = true,
  ...props
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const urlParams = useParams();
  const location = useLocation();
  const { business_id } = useTenant();

  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);

  const payload = { business_id, ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading } = useQuery({
    queryKey: [apiCall.list.queryKey, payload],
    queryFn: () => apiCall.list.queryFn(payload),
  });

  const onFiltering = values => {
    var values = Object.fromEntries(
      Object.entries(values).filter(([k, v]) => v)
    );

    const queryString = new URLSearchParams(values).toString();
    const pathName = location.pathname;

    navigate(pathName + `?${queryString}`);
    // fetchItems({ ...urlParams, ...values, ...params });
  };

  const { items = [], paging } = data;
  const newCreateUrl = createUrl ?? `/${business_id}/${namespace}/create`;

  return (
    <>
      <PageTitle
        title={title}
        showBreadscrumb={showBreadscrumb}
        extra={
          extra ||
          (newCreateUrl && [
            <Button key="1" type="primary">
              <Link to={newCreateUrl}>{createLabel || 'Create'}</Link>
            </Button>,
          ])
        }
      />
      {FilterForm && (
        <FilterForm
          form={form}
          onFinish={onFiltering}
          submiting={isLoading}
          initialValues={searchParams}
          name="filter_form"
        />
      )}
      <DataTable
        rowKey="id"
        columns={columnGenerator(listProps)}
        dataSource={items}
        totalItems={paging?.total_items}
        currentPage={paging?.current_page || paging?.page}
        defaultCurrent={1}
        defaultPageSize={paging?.page_size || paging?.per_page}
        pageSize={paging?.page_size || paging?.per_page}
        //dataFetcher={fetchItems}
        loading={isLoading}
        //filters keep filter params when go to another page
        filters={{ ...urlParams, ...searchParams, ...params }}
        {...props}
      />
    </>
  );
};

export default AdminList;
