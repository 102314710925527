import apiCall from 'apiCalls/supplier';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const SupplierListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      namespace="suppliers"
      searchOptions={{ tooltip: 'Enter name, email or phone number' }}
      title="All Suppliers"
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
      resourceName="items"
    />
  );
};

export const SupplierEditPage = props => {
  const { id } = useParams();

  return (
    <EditPage
      namespace="suppliers"
      apiCall={apiCall}
      ItemForm={ItemForm}
      title="Edit supplier"
      resourceName=""
      {...props}
    />
  );
};

export const SupplierDetailPage = props => {
  return (
    <DetailPage
      namespace="suppliers"
      title="Suppliers"
      apiCall={apiCall}
      ItemDetail={ItemDetail}
      resourceName=""
      options={{ deleteEnabled: true }}
      {...props}
    />
  );
};

export const SupplierCreatePage = props => (
  <CreatePage
    namespace="suppliers"
    title="Suppliers"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
