export const incomeStatusColors = {
  1000: '#f0f0f0',
  1001: '#FFD700',
  1002: '#52c41a',
  1003: '#a9a9a9',
};

export const incomeStatusLabels = {
  1000: 'New',
  1001: 'Pending',
  1002: 'Completed',
  1003: 'Cancelled',
};
