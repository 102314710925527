import { accountTypes, businessLayout } from 'constants/invoice';

const UserInfo = ({
  user,
  invoiceData = {},
  paymentAccountDisplayName,
  variant = businessLayout.SIMPLE,
  children,
}) => {
  const isPaymentAccount = user?.account_type === accountTypes.PAYMENT;
  const displayName = isPaymentAccount ? paymentAccountDisplayName : user.name;

  const phoneNumber = invoiceData.phone_number || user.phone;
  const email = invoiceData.customer_email || user.email;
  const overrideAddress = !!invoiceData.billing_address;

  return (
    <div>
      <div className="grid grid-cols-2 gap-2 mt-4">
        <div>
          <p className="font-bold text-xs mb-2">
            {user.account_type == accountTypes.CUSTOMER
              ? 'Billing Address:'
              : 'SUPPLIER:'}
          </p>
          <p>
            <b>
              {displayName.toUpperCase()}
              {user.registration_number && ` (${user.registration_number})`}
            </b>
          </p>
          {isPaymentAccount && <p className="semibold">{user?.code}</p>}
          {overrideAddress ? (
            <>
              <p>{invoiceData.billing_address}</p>
              <p>{invoiceData.billing_address2}</p>
              <p>{invoiceData.billing_address3}</p>
            </>
          ) : (
            <>
              <p>{user.address1}</p>
              <p>{user.address2}</p>
              <p>{user.address3}</p>
            </>
          )}
          {variant === businessLayout.SIMPLE && phoneNumber && (
            <p>Tel: {phoneNumber}</p>
          )}
        </div>
        <div>
          <p className="font-bold text-xs mb-2">Shipping Address:</p>
          <div style={{ minHeight: 50 }}>
            <p style={{ overflowWrap: 'anywhere' }}>
              {invoiceData.shipping_address}
            </p>
            {invoiceData.shipping_name ? (
              <p className="grid grid-cols-3 gap-2">
                <span>Contact:</span>
                <span
                  className="col-span-2"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {invoiceData.shipping_name}
                </span>
              </p>
            ) : null}
            {invoiceData.shipping_phone_number ? (
              <p className="grid grid-cols-3 gap-2">
                <span>Phone Number:</span>
                <span
                  className="col-span-2"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {invoiceData.shipping_phone_number}
                </span>
              </p>
            ) : null}
          </div>
        </div>
      </div>
      {variant === businessLayout.DETAILED ? (
        <div className="grid grid-cols-2 gap-2 mt-4">
          <div>
            <div>
              <p className="grid grid-cols-3 gap-2">
                <b>Phone:</b>
                <span className="col-span-2">{phoneNumber || '-'}</span>
              </p>
              <p className="grid grid-cols-3 gap-2">
                <b>Email:</b>
                <span
                  className="col-span-2"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {email || '-'}
                </span>
              </p>
              <p className="grid grid-cols-3 gap-2">
                <b>TIN:</b>
                <span
                  className="col-span-2"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {user.tax_number || '-'}
                </span>
              </p>
              <p className="grid grid-cols-3 gap-2">
                <b>
                  {user.account_type == accountTypes.CUSTOMER
                    ? 'Debtor ID:'
                    : 'Creditor ID:'}
                </b>
                <span className="col-span-2">
                  {user.account_type == accountTypes.CUSTOMER
                    ? user.debtor_code
                    : user.creditor_code}
                </span>
              </p>
            </div>
          </div>
          <div>{children}</div>
        </div>
      ) : null}
    </div>
  );
};

export default UserInfo;
