import { Descriptions } from 'antd';
import React, { useEffect } from 'react';

import { businessLayout } from 'constants/invoice';
import { formatCurrency } from 'utils/formatAmount';
import { formatDate } from 'utils/dateUtils';
import { useFetchApiGet } from 'common/reduxutils';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import InvoiceBody from 'components/Invoice/InvoiceBody';
import InvoiceFooter from 'components/Invoice/Footer';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import Printable from 'components/Printable';
import Square from 'components/Invoice/Square';
import UserInfo from 'components/Invoice/UserInfo';
import apiCall from 'apiCalls/business';

import { formatDateTime } from '../../utils/dateUtils';

const InvoiceItemDetail = ({ business_id, item }) => {
  const {
    data: companyInfo,
    load: fetchBusinessInfo,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchBusinessInfo({ id: business_id });
  }, [business_id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Printable
        title={`Expense Invoice ${item.code}`}
        HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
          <>
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <BusinessInfo data={companyInfo} />
              </div>
              <div className="flex flex-col items-center">
                <Square invoiceName="Expense Invoice" />
                <p className="mt-2">
                  <b>EXPENSE INVOICE NO: {item.code}</b>
                </p>
              </div>
            </div>

            <hr className="my-5" />

            <UserInfo
              user={item.user}
              paymentAccountDisplayName="Cash Expense"
              invoiceData={item}
              variant={businessLayout.DETAILED}
            >
              <div className="grid grid-cols-3 gap-2">
                <div>
                  <p>
                    <b>Date:</b>
                  </p>
                  <p>
                    <b>Reference No.:</b>
                  </p>
                  <p>
                    <b>Branch:</b>
                  </p>
                  <p>
                    <b>TYPE:</b>
                  </p>
                  {totalPrintPage > 1 ? (
                    <p>
                      <b>Page:</b>
                    </p>
                  ) : null}
                </div>
                <div className="col-span-2">
                  <p>{formatDate(item.created)}</p>
                  <p>{item.reference_number || '-'}</p>
                  <p>{item.branch?.name || '-'}</p>
                  <p>
                    <b>Salary and Wages</b>
                  </p>
                  {totalPrintPage > 1 ? (
                    <p>
                      {currentPrintPage} of {totalPrintPage}
                    </p>
                  ) : null}
                </div>
              </div>
            </UserInfo>
          </>
        )}
        DataTableComponent={({ items }) => (
          <InvoiceBody
            itemsData={items}
            columns={[
              {
                title: 'Particular',
                dataIndex: 'particular',
              },
              {
                title: 'Quantity',
                dataIndex: 'quantity',
                align: 'right',
              },
              {
                title: 'Price',
                dataIndex: 'price',
                align: 'right',
                render: value => formatCurrency(value),
              },
              {
                title: 'Amount',
                dataIndex: 'amount',
                align: 'right',
                render: value => formatCurrency(value),
              },
              {
                title: 'Tax',
                dataIndex: 'tax',
                align: 'right',
                render: value => formatCurrency(value),
              },
            ]}
          />
        )}
        SummaryComponent={() => (
          <InvoiceFooter
            item={{ ...item, total_amount: item.amount }}
            companyInfo={companyInfo}
          />
        )}
        items={item.expense_items}
      />
      <Descriptions column={1} bordered className="my-5">
        <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
        <Descriptions.Item label="Personal Notes">
          {item.personal_notes}
        </Descriptions.Item>
        <Descriptions.Item label="Attachment">
          {item.file ? (
            <a href={item.file} target="_blank" rel="noreferrer">
              Open Attachment
            </a>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {formatDateTime(item.created)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated Date">
          {formatDateTime(item.modified)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default InvoiceItemDetail;
