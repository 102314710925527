import { Descriptions } from 'antd';
import React, { useEffect } from 'react';

import { accountTypes } from 'constants/invoice';
import { formatCurrency } from 'utils/formatAmount';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { paymentMethods } from 'constants/paymentMethod';
import { useFetchApiGet } from 'common/reduxutils';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import InvoiceBody from 'components/Invoice/InvoiceBody';
import InvoiceFooter from 'components/Invoice/Footer';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import Printable from 'components/Printable';
import Square from 'components/Invoice/Square';
import apiCall from 'apiCalls/business';

const InvoiceItemDetail = ({ business_id, item }) => {
  const {
    data: companyInfo,
    load: fetchBusinessInfo,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchBusinessInfo({ id: business_id });
  }, [business_id]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const paymentAccount = item.payment_account;

  const [payTo, payFrom] =
    item.payment_type === 'inflow'
      ? [companyInfo, item.user]
      : [item.user, companyInfo];

  return (
    <>
      <Printable
        title={`Payment Voucher ${item.code}`}
        HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
          <>
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <BusinessInfo data={companyInfo} />
              </div>
              <div className="flex flex-col items-center">
                <Square invoiceName="Payment Voucher" />
                <p className="mt-2">
                  <b>PAYMENT VOUCHER NO: {item.code}</b>
                </p>
              </div>
            </div>

            <hr className="my-5" />

            <div className="grid grid-cols-2 gap-2 mt-4">
              <div>
                <p className="font-bold text-xs mb-2 uppercase">PAY FROM:</p>
                <p>
                  <b>
                    {(payFrom.company_name || payFrom.name).toUpperCase()}
                    {payFrom.registration_number &&
                      ` (${payFrom.registration_number})`}
                  </b>
                </p>
                {payFrom.account_type == accountTypes.PAYMENT && (
                  <p className="semibold">{payFrom?.code}</p>
                )}
                <p>{payFrom.address1}</p>
                <p>{payFrom.address2}</p>
                <p>{payFrom.address3}</p>
              </div>
              <div>
                <p className="font-bold text-xs mb-2 uppercase">PAY TO:</p>
                <p>
                  <b>
                    {(payTo.company_name || payTo.name).toUpperCase()}
                    {payTo.registration_number &&
                      ` (${payTo.registration_number})`}
                  </b>
                </p>
                <p>{payTo.address1}</p>
                <p>{payTo.address2}</p>
                <p>{payTo.address3}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2 mt-4">
              <div>
                <div>
                  <p className="grid grid-cols-3 gap-2">
                    <b>Phone:</b>
                    <span className="col-span-2">{item.user.phone || '-'}</span>
                  </p>
                  <p className="grid grid-cols-3 gap-2">
                    <b>Email:</b>
                    <span
                      className="col-span-2"
                      style={{ overflowWrap: 'anywhere' }}
                    >
                      {item.user.email || '-'}
                    </span>
                  </p>
                  <p className="grid grid-cols-3 gap-2">
                    <b>TIN:</b>
                    <span
                      className="col-span-2"
                      style={{ overflowWrap: 'anywhere' }}
                    >
                      {item.user.tax_number || '-'}
                    </span>
                  </p>
                  <p className="grid grid-cols-3 gap-2">
                    <b>
                      {item.user.account_type == accountTypes.CUSTOMER
                        ? 'Debtor ID:'
                        : 'Creditor ID:'}
                    </b>
                    <span className="col-span-2">
                      {item.user.account_type == accountTypes.CUSTOMER
                        ? item.user.debtor_code
                        : item.user.creditor_code}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-3 gap-2">
                  <div>
                    <p>
                      <b>Date:</b>
                    </p>
                    <p>
                      <b>Reference No:</b>
                    </p>
                    <p>
                      <b>Branch:</b>
                    </p>
                    <p>
                      <b>Account:</b>
                    </p>
                    {totalPrintPage > 1 ? (
                      <p>
                        <b>Page:</b>
                      </p>
                    ) : null}
                  </div>
                  <div className="col-span-2">
                    <p>{formatDate(item.date)}</p>
                    <p>{item.reference_number || '-'}</p>
                    <p>{item.branch?.name || '-'}</p>
                    <p>
                      <b>{paymentAccount.code}</b>
                    </p>
                    {totalPrintPage > 1 ? (
                      <p>
                        {currentPrintPage} of {totalPrintPage}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        DataTableComponent={({ items }) => (
          <InvoiceBody
            itemsData={items}
            columns={[
              {
                title: 'Particular',
                dataIndex: 'particular',
              },
              {
                title: 'Amount',
                dataIndex: 'amount',
                align: 'right',
                render: value => formatCurrency(value),
              },
              {
                title: 'Payment Mode',
                dataIndex: 'payment_method',
                render: value => paymentMethods[value],
                align: 'center',
              },
              {
                title: 'Tax',
                dataIndex: 'tax',
                align: 'right',
                render: value => formatCurrency(value),
              },
            ]}
          />
        )}
        SummaryComponent={() => (
          <InvoiceFooter
            item={{ ...item, total_amount: item.amount }}
            companyInfo={companyInfo}
          />
        )}
        items={item.tx_items}
      />
      <Descriptions column={1} bordered className="my-5">
        <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
        <Descriptions.Item label="Personal Notes">
          {item.personal_notes}
        </Descriptions.Item>
        <Descriptions.Item label="Attachment">
          {item.file ? (
            <a href={item.file} target="_blank" rel="noreferrer">
              Open Attachment
            </a>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {formatDateTime(item.created)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated Date">
          {formatDateTime(item.modified)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default InvoiceItemDetail;
