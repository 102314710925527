import { Button } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import React from 'react';

import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import apiCall from 'apiCalls/order';
import useTenant from 'components/use-tenant';

import { columnsGenerator } from './config';
import { getTenantUrl } from '../../utils/urlHelpers';
import InvoiceItemDetail from './invoice';
import ItemDetail from './detail';
import OrderForm from './forms/OrderForm';

export const OrderListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Pending Sales Orders"
      namespace="orders"
      searchOptions={{ tooltip: 'Search by invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'branches'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const CompletedSalesOrderListPage = () => {
  const [baseSearchParams] = useSearchParams();
  const { business_id } = useTenant();
  const showColumns = [
    'date',
    'code',
    'user',
    'total_amount',
    'modified',
    'status',
    'action',
  ];

  return (
    <ListPage
      title="All Completed Sales Orders"
      namespace="orders"
      searchOptions={{ tooltip: 'Search by invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'branches'] }}
      apiCall={apiCall}
      params={{ status: 1002 }}
      createUrl=""
      columnGenerator={() =>
        columnsGenerator({ business_id }).filter(column => {
          return (
            showColumns.includes(column.dataIndex) ||
            showColumns.includes(column.key)
          );
        })
      }
      extraBtns={
        <Button type="primary">
          <Link
            to={getTenantUrl(
              business_id,
              `/orders/completed/statement?${baseSearchParams.toString()}`
            )}
          >
            Statement
          </Link>
        </Button>
      }
    />
  );
};

export const OrderDetailPage = props => (
  <DetailPage
    title="Sale Detail"
    namespace="orders"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const OrderInvoicePage = props => (
  <DetailPage
    title="Sale Invoice"
    namespace="orders"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);

export const OrderEditPage = props => (
  <EditPage
    title="Sale Edit"
    namespace="orders"
    apiCall={apiCall}
    ItemForm={OrderForm}
    resourceName=""
    {...props}
  />
);

export const OrderCreatePage = props => (
  <CreatePage
    title="Sale Create"
    namespace="orders"
    apiCall={apiCall}
    ItemForm={OrderForm}
    resourceName=""
    {...props}
  />
);

export { StatementPage as CompletedSalesOrderStatementPage } from './statement';
