import { compact, join, sumBy, flow, map, flatten } from 'lodash/fp';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import moment from 'moment';

import { DetailPage } from 'common/ui/AdminCRUD/page';
import { formatCurrency } from 'utils/formatAmount';
import { formatDate } from 'utils/dateUtils';
import { useFetchApiGet } from 'common/reduxutils';
import BusinessInfo from 'components/Invoice/BusinessInfo';
import DataTable from 'common/ui/DataTable';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import Printable from 'components/Printable';
import businessApiCall from 'apiCalls/business';
import purchaseReturnApiCall from 'apiCalls/purchaseReturn';
import useTenant from 'components/use-tenant';
import userApiCall from 'apiCalls/user';

import { SummaryComponent } from '../Order/statement';

const HeaderComponent = ({
  currentPrintPage,
  totalPrintPage,
  companyInfo,
  user,
  statementDate,
}) => {
  return (
    <div>
      <BusinessInfo data={companyInfo} />

      <div className="flex justify-center items-center my-4">
        <p>
          <strong>Purchase Returns Statement</strong>
        </p>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <div className="grid grid-cols-4 gap-2">
            <div>
              <p>Debtor:</p>
              <p>Phone:</p>
              <p>Address:</p>
            </div>
            <div className="col-span-3">
              <p>{user ? user.name : '_'}</p>
              <p>{user ? user.phone : '_'}</p>
              <p>
                {user
                  ? flow(
                      compact,
                      join(', ')
                    )([user.address1, user.address2, user.address3])
                  : '_'}
              </p>
            </div>
          </div>
        </div>
        <div />
        <div className="col-span-5">
          <div className="grid grid-cols-4 gap-2">
            <div>
              <p>Date:</p>
              <p>Page:</p>
            </div>
            <div className="col-span-3">
              <p>{statementDate}</p>
              <p>
                {currentPrintPage} of {totalPrintPage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DataTableComponent = ({ items, paging }) => {
  return (
    <DataTable
      rowKey="id"
      columns={[
        {
          title: 'Date',
          dataIndex: 'date',
          render: value => formatDate(value),
        },
        {
          title: 'No.',
          dataIndex: 'code',
        },
        {
          title: 'Debtor',
          dataIndex: 'user',
          render: user => user?.name,
        },
        {
          title: 'Branch',
          dataIndex: 'branch',
          render: branch => branch?.name,
        },
        {
          title: 'Amount',
          dataIndex: 'total_amount',
          align: 'right',
          render: value => (value > 0 ? formatCurrency(value) : ''),
        },
      ]}
      dataSource={items}
      totalItems={paging?.total_items}
      currentPage={paging?.current_page || paging?.page}
      defaultCurrent={1}
      defaultPageSize={paging?.page_size || paging?.per_page}
      pageSize={paging?.page_size || paging?.per_page}
      size="small"
      pagingEnabled={!!paging}
    />
  );
};

const useAllPurchaseReturnsQuery = ({ page, per_page, ...payload }) => {
  const {
    data: { pages } = { pages: [] },
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [purchaseReturnApiCall.list.queryKey, payload, 'print'],
    queryFn: context =>
      purchaseReturnApiCall.list.queryFn({
        ...payload,
        page: context.pageParam,
        per_page: 500,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, _lastPageParams, _allPageParams) => {
      if (lastPage.paging.page < lastPage.paging.page_count) {
        return lastPage.paging.page + 1;
      }
    },
  });

  React.useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [pages.length]);

  const allItems = React.useMemo(
    () =>
      flow(
        map(page => page.items),
        flatten
      )(pages),
    [pages.length]
  );

  return [
    allItems.sort(
      (item1, item2) =>
        moment(item1.date) - moment(item2.date) ||
        moment(item1.created) - moment(item2.created)
    ),
    hasNextPage,
  ];
};

const StatementItemDetail = ({ business_id, item: user }) => {
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useFetchApiGet(businessApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const urlParams = useParams();
  const params = { status: 1002 };
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading: isLoadingData } = useQuery({
    queryKey: [purchaseReturnApiCall.list.queryKey, payload],
    queryFn: () => purchaseReturnApiCall.list.queryFn(payload),
  });

  const { items = [], paging } = data;
  const isLoading = isLoadingCompanyInfo || isLoadingData;

  const [printItems, stillFetchingPrintItems] =
    useAllPurchaseReturnsQuery(payload);
  const total = sumBy(item => Number(item.total_amount))(printItems);

  const statementDate =
    searchParams['date__gte'] || searchParams['date__lte']
      ? [
          formatDate(searchParams['date__gte']) || 'Beginning',
          formatDate(searchParams['date__lte']) || 'Present',
        ].join(' - ')
      : formatDate(new Date());

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Printable
      title="Statement"
      HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
        <HeaderComponent
          currentPrintPage={currentPrintPage}
          totalPrintPage={totalPrintPage}
          companyInfo={companyInfo}
          user={user}
          statementDate={statementDate}
        />
      )}
      DataTableComponent={DataTableComponent}
      SummaryComponent={() => (
        <SummaryComponent total={total} companyInfo={companyInfo} />
      )}
      items={items}
      printItems={printItems}
      paging={paging}
      chunkSize={15}
      readyToPrint={stillFetchingPrintItems === false}
    />
  );
};

export const StatementPage = props => {
  const { business_id } = useTenant();
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);

  if (searchParams.user_id) {
    return (
      <DetailPage
        title=""
        apiCall={userApiCall}
        ItemDetail={StatementItemDetail}
        resourceName="items"
        options={{ id: searchParams.user_id }}
        {...props}
      />
    );
  }

  return (
    <>
      <PageTitle title="View details" />
      <StatementItemDetail business_id={business_id} />
    </>
  );
};
